import { API } from '@/api'
import { IUuid } from '@/types'

export async function fetchJobApplicationAttachmentFileLink(
    jobId: number,
    jobApplicationId: number,
    attachmentName: string
): Promise<string> {
    const link = `${API.defaults.baseURL}/jobs/${jobId}/applications/${jobApplicationId}/attachments/${attachmentName}`

    return link
}

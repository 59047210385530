export enum JOB_APPLICATION_STATE_ID {
    NEW = 1,
    ACCEPTED = 2,
    SHORTLISTED = 3,
    REJECTED = 4,
    HIRED = 5,
}

export enum JOB_APPLICATION_ACTION_ID {
    ACCEPT = 1,
    SHORTLIST = 2,
    MARK_AS_HIRED = 3,
    REJECT = 4,
}

import axios, { AxiosError, AxiosResponse } from 'axios'
import { ApiError } from './models'
import { IApiErrorResponse } from './types'
import { store } from '@/store/root'
import { STORE_MODULE } from '@/enums'
import { IStateAuth } from '@/types'
import { refreshTokenIfNearlyExpired, signOutAndRedirect } from './utils'

const API = axios.create({
    withCredentials: true,
    baseURL: process.env.VUE_APP_BACKEND_URL,
})

API.interceptors.request.use(async (config) => {
    try {
        await refreshTokenIfNearlyExpired()
    } catch (error) {
        await signOutAndRedirect()
    }

    const state = (store.state as unknown) as { [STORE_MODULE.AUTH]: IStateAuth }
    const token = state[STORE_MODULE.AUTH].userAccount?.token

    if (token) config.headers.common['Authorization'] = `Bearer ${token}`

    return config
})

API.interceptors.response.use(
    (response: AxiosResponse): AxiosResponse => response,
    (error: AxiosError): Promise<IApiErrorResponse> => {
        const apiError = new ApiError(error)

        if (apiError.response.status === 403) signOutAndRedirect()

        // @see: https://github.com/axios/axios/issues/960
        return Promise.reject(apiError.response)
    }
)

export { API }

import { API } from '@/api'
import { ICity, IUuid } from '@/types'

export async function fetchPreferredCities(specificationUuid: IUuid): Promise<ICity[]> {
    const { data } = await API.get<ICity[]>(
        `users/me/new-job-specifications/${specificationUuid}/preferred-cities`
    )

    return data
}

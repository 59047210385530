import { API } from '@/api'
import { API_LIST_REQUEST_LIMIT } from '@/enums'
import { IJob, IJobSearchListRequestParams, IJobSearchListRequestResult } from '@/types'

export async function fetchJobs({
    offset,
    limit = API_LIST_REQUEST_LIMIT,
    filters,
}: IJobSearchListRequestParams): Promise<IJobSearchListRequestResult> {
    const params = { offset, limit, ...filters }
    const { data } = await API.get<IJob[]>('jobs/search', { params })

    return {
        items: data,
        hasMoreItems: data.length === limit,
    }
}

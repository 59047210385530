import Vue from 'vue'
import axios from 'axios'

const state = {
    userSubscriptionComplete: false,
    userSubscriptionRegistered: false,
    userSubscriptionConfirmed: false,
}

export const actions = {
    async subscribeUser({ commit, rootState }, { name, email }) {
        const subscriptionApiUrl = '/subscribe/'
        const subscritionBody = {
            name,
            email,
        }

        const response = await rootState.backend.post(subscriptionApiUrl, subscritionBody)

        const subscriptionComplete = response.status == 201
        const subscriptionRegistered = response.status == 209

        commit('setSubscriptionState', {
            subscriptionComplete,
            subscriptionRegistered,
        })
    },

    async confirmSubscription({ commit, rootState }, { sId, kId }) {
        const subscriptionApiUrl = '/subscribe/confirm/'
        const subscritionBody = {
            sId,
            kId,
        }
        const response = await rootState.backend.post(subscriptionApiUrl, subscritionBody)

        const subscriptionConfirmed = response.status == 200

        commit('setSubscriptionStateConfirmation', subscriptionConfirmed)
    },
}

export const mutations = {
    setSubscriptionState(state, payload) {
        const { subscriptionComplete, subscriptionRegistered } = payload

        Vue.set(state, 'userSubscriptionComplete', subscriptionComplete)
        Vue.set(state, 'userSubscriptionRegistered', subscriptionRegistered)
    },
    setSubscriptionStateConfirmation(state, payload) {
        Vue.set(state, 'userSubscriptionConfirmed', payload)
    },
}

export default {
    namespaced: true,
    state,
    mutations,
    actions,
}

import { API } from '@/api'
import { IProcess, IProcessConfig } from '@/types'

export async function getProcesses(companyId: number): Promise<IProcess[]> {
    const { data } = await API.get<IProcess[]>(`/process/all/${companyId}`)
    return data
}

export async function cloneProcess(processId: number): Promise<IProcess> {
    const { data } = await API.post<IProcess>(`/process/clone/${processId}`)
    return data
}

export async function addProcess(payload: IProcessConfig): Promise<IProcess> {
    const { data } = await API.post<IProcess>(`/process/add`, payload)
    return data
}

export async function updateProcess(payload: IProcess): Promise<IProcess> {
    const { data } = await API.put<IProcess>(`/process/update`, payload)
    return data
}

export async function deleteProcess(processId: number): Promise<void> {
    await API.delete<IProcess>(`/process/delete/${processId}`)
}

import { API } from '@/api'
import { API_LIST_REQUEST_LIMIT_AUTOCOMPLETE } from '@/enums'
import { IListRequestParams, IListRequestResult } from '@/types'

export function fetchManyFnFactory<T>(url: string) {
    return async function fetch({
        offset = 0,
        limit = API_LIST_REQUEST_LIMIT_AUTOCOMPLETE,
        searchText = '',
    }: IListRequestParams): Promise<IListRequestResult<T>> {
        const params = { offset, limit, searchText }
        const { data } = await API.get<T[]>(url, { params })

        return {
            items: data,
            hasMoreItems: data.length === limit,
        }
    }
}

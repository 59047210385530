import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import { TOKEN_LOCAL_STORAGE_KEY } from '@/enums'

Vue.use(Vuex)

/**
 * @deprecated
 * @description Use or create repository
 * */
const backend = axios.create({
    baseURL: process.env.VUE_APP_BACKEND_URL,
    timeout: 5000,
    crossDomain: true,
    validateStatus: function (status) {
        return status >= 200 && status < 500
    },
})

backend.interceptors.request.use((config) => {
    const token = localStorage.getItem(TOKEN_LOCAL_STORAGE_KEY)

    if (token) config.headers.common['Authorization'] = `Bearer ${token}`

    return config
})

const state = {
    backend,

    // TODO
    /** deprecated */
    filterDefaults: {
        perks: [
            'Free beverages',
            'Free snacks',
            'Free lunch',
            'Kitchen/canteen',
            'In-house trainings',
            'Training budget',
            'Office gym',
            'Shower',
            'Sports subscription',
            'Bike parking',
            'Car parking',
            'In-house hack days',
            'Team events',
            'Play room',
            'Private health care',
            'Kindergarten',
        ],
    },
}

export const store = new Vuex.Store({ state })

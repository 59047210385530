import { API } from '@/api'
import { IJobApplicationPayload } from '@/types'
import { IRawJobApplication, IRawJobApplicationPayload } from '../types'
import { createJobApplicationAttachments } from './createJobApplicationAttachments'

export async function createJobApplication(payload: IJobApplicationPayload): Promise<void> {
    const { jobId, filesToAttach } = payload

    const { data: rawJobApplication } = await API.post<IRawJobApplication>(
        `jobs/${jobId}/applications`,
        getRawPayload(payload)
    )

    if (filesToAttach.length) {
        await createJobApplicationAttachments(jobId, rawJobApplication.id, filesToAttach)
    }
}

function getRawPayload(payload: IJobApplicationPayload): IRawJobApplicationPayload {
    const {
        referralId,
        firstName,
        lastName,
        email,
        noticePeriod: { id: noticePeriodId },
        areAllSkillsMatched: allSkillsMatched,
        isPartTimeAvailable: partTimeAvailable,
        descriptionWhatIsMissing: whatsMissing,
        descriptionWhyImGood: whyImGood,
    } = payload
    return {
        referralId,
        firstName,
        lastName,
        email,
        noticePeriodId,
        allSkillsMatched,
        partTimeAvailable,
        whatsMissing,
        whyImGood,
    }
}

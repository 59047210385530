import { API } from '@/api'
import { ICredentials, IUserAccount } from '@/types'
import { UserAccount } from '../models/UserAccount'
import { fetchUserFromToken } from './fetchUserFromToken'

export async function fetchUserAccountFromCredentials(
    credentials: ICredentials
): Promise<IUserAccount> {
    const { data: token } = await API.post<string>('auth/sign-in', credentials)
    const user = await fetchUserFromToken(token)

    return new UserAccount(token, user)
}

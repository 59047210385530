import { ability } from '@/ability'
import { Route } from 'vue-router'

export function canNavigateTo(to: Route): boolean {
    return to.matched.every((route) => {
        const { abilityAction, abilitySubject } = route.meta

        if (abilityAction === undefined || abilitySubject === undefined) return true

        return ability.can(abilityAction, abilitySubject)
    })
}

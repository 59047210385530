import { IApiCacheOptions } from '../types'

export class ApiCache<K, V> {
    private cachedItems: Map<K, V>
    private cachedExpirationTimestamps: Map<K, number>
    private expiration: boolean
    private expirationTime: number

    constructor(options?: IApiCacheOptions) {
        this.cachedItems = new Map<K, V>()
        this.cachedExpirationTimestamps = new Map<K, number>()
        this.expiration = options?.expiration || false
        this.expirationTime = options?.expirationTime || 5 /* min */ * 6e4
    }

    public set(key: K, value: V): void {
        if (this.expiration) this.cachedExpirationTimestamps.set(key, Date.now())

        this.cachedItems.set(key, value)
    }

    public get(key: K): V | undefined {
        if (this.expiration) this.deleteIfExpired(key)

        return this.cachedItems.get(key)
    }

    private deleteIfExpired(key: K): void {
        if (this.cachedItems.get(key) == null) return

        const itemTimestamp = this.cachedExpirationTimestamps.get(key) ?? 0

        if (itemTimestamp + this.expirationTime > Date.now()) return

        this.delete(key)
    }

    public delete(key: K): void {
        this.cachedItems.delete(key)
        this.cachedExpirationTimestamps.delete(key)
    }

    public clear(): void {
        this.cachedItems.clear()
        this.cachedExpirationTimestamps.clear()
    }
}

import {
    MUTATION_USER_NEW_JOB_SPECIFICATIONS,
    ACTION_DASHBOARD_USER_NEW_JOB_SPECIFICATIONS,
} from '@/enums'
import {
    REPOSITORY_USER_NEW_JOB_SPECIFICATIONS,
    REPOSITORY_USER_NEW_JOB_SPECIFICATION_DETAILS,
} from '@/repositiories'
import {
    IStateDashboardUserNewJobSpecifications,
    IStateRoot,
    IUserNewJobSpecification,
    IUserNewJobSpecificationConfig,
    IUuid,
} from '@/types'
import { ActionTree, MutationTree } from 'vuex'

const state = (): IStateDashboardUserNewJobSpecifications => ({
    specifications: undefined,
})

export const mutations: MutationTree<IStateDashboardUserNewJobSpecifications> = {
    [MUTATION_USER_NEW_JOB_SPECIFICATIONS.SET_SPECIFICATIONS](
        state,
        specifications: IUserNewJobSpecification[]
    ) {
        state.specifications = specifications
    },

    [MUTATION_USER_NEW_JOB_SPECIFICATIONS.REMOVE_SPECIFICATION](
        state,
        specificationUuid: IUuid
    ) {
        if (!state.specifications) return

        state.specifications = state.specifications.filter(
            ({ uuid }) => uuid !== specificationUuid
        )
    },
}

export const actions: ActionTree<IStateDashboardUserNewJobSpecifications, IStateRoot> = {
    async [ACTION_DASHBOARD_USER_NEW_JOB_SPECIFICATIONS.FETCH_AND_SET_SPECIFICATIONS]({
        commit,
    }): Promise<void> {
        try {
            const specifications = await REPOSITORY_USER_NEW_JOB_SPECIFICATIONS.fetchSpecifications()

            commit(MUTATION_USER_NEW_JOB_SPECIFICATIONS.SET_SPECIFICATIONS, specifications)
        } catch (error) {
            alert(error.data.message)

            return Promise.reject(error)
        }
    },

    async [ACTION_DASHBOARD_USER_NEW_JOB_SPECIFICATIONS.CREATE_SPECIFICATION](
        { dispatch },
        specificationConfig: Pick<IUserNewJobSpecificationConfig, 'name'>
    ): Promise<IUserNewJobSpecification> {
        try {
            const specification = await REPOSITORY_USER_NEW_JOB_SPECIFICATION_DETAILS.createSpecification(
                specificationConfig
            )
            await dispatch(
                ACTION_DASHBOARD_USER_NEW_JOB_SPECIFICATIONS.FETCH_AND_SET_SPECIFICATIONS
            )

            return specification
        } catch (error) {
            alert(error.data.message)

            return Promise.reject(error)
        }
    },

    async [ACTION_DASHBOARD_USER_NEW_JOB_SPECIFICATIONS.DELETE_SPECIFICATION](
        { commit },
        specificationUuid: IUuid
    ): Promise<void> {
        try {
            await REPOSITORY_USER_NEW_JOB_SPECIFICATION_DETAILS.deleteSpecification(
                specificationUuid
            )

            commit(MUTATION_USER_NEW_JOB_SPECIFICATIONS.REMOVE_SPECIFICATION, specificationUuid)
        } catch (error) {
            alert(error.data.message)

            return Promise.reject(error)
        }
    },
}

export const dashboardUserNewJobSpecifications = {
    namespaced: true,
    state,
    mutations,
    actions,
}

import { ABILITY_ACTION, ABILITY_SUBJECT, ROUTE } from '@/enums'
import { IRouteConfig } from '../types'

export const routesAuthenticated: IRouteConfig[] = [
    {
        name: ROUTE.DASHBOARD,
        path: '/dashboard',
        redirect: '/dashboard/jobs',
        component: () =>
            import(
                /* webpackChunkName: "dashboard" */ '@/pages/TheDashboardFrame/TheDashboardFrame.vue'
            ),
        children: [
            {
                name: ROUTE.DASHBOARD_COMPANY,
                path: 'company-about',
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-company-about" */ '@/components/AdminManageDataCompanies.vue'
                    ),
                meta: {
                    title: 'Your company',
                    abilityAction: ABILITY_ACTION.READ,
                    abilitySubject: ABILITY_SUBJECT.AUTHENTICATED,
                },
            },
            {
                name: ROUTE.DASHBOARD_COMPANY_DEPARTMENTS,
                path: 'company-departments',
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-company-departments" */ '@/pages/TheDashboardFrame/views//Departments/Departments.vue'
                    ),
                meta: {
                    title: 'Your company departments',
                    abilityAction: ABILITY_ACTION.READ,
                    abilitySubject: ABILITY_SUBJECT.AUTHENTICATED,
                },
            },
            {
                name: ROUTE.DASHBOARD_COMPANY_ADDRESSES,
                path: 'company-addresses',
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-company-addresses" */ '@/pages/Company/CompanyAddresses/CompanyAddresses.vue'
                    ),
                meta: {
                    title: 'Your company addresses',
                    abilityAction: ABILITY_ACTION.READ,
                    abilitySubject: ABILITY_SUBJECT.AUTHENTICATED,
                },
            },
            {
                name: ROUTE.DASHBOARD_RECRUITMENT_PROCESS,
                path: 'recruitment-processes',
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-recruitment-processes" */ '@/pages/TheDashboardFrame/views/DashbaordJobRecruitmentProcess/DashbaordJobRecruitmentProcess.vue'
                    ),
                meta: {
                    title: 'Recruitment process',
                    abilityAction: ABILITY_ACTION.READ,
                    abilitySubject: ABILITY_SUBJECT.AUTHENTICATED,
                },
            },
            {
                name: ROUTE.DASHBOARD_REFERRED_PEOPLE,
                path: 'referred-people',
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-referred-people" */ '@/pages/TheDashboardFrame/views/ReferredPeople/ReferredPeople.vue'
                    ),
                meta: {
                    title: 'Referred people',
                    abilityAction: ABILITY_ACTION.READ,
                    abilitySubject: ABILITY_SUBJECT.AUTHENTICATED,
                },
            },
            {
                name: ROUTE.DASHBOARD_RESPONSE_RATE,
                path: 'response-rate',
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-response-rate" */ '@/pages/TheDashboardFrame/views/ResponseRate/ResponseRate.vue'
                    ),
                meta: {
                    title: 'Response rate',
                    abilityAction: ABILITY_ACTION.READ,
                    abilitySubject: ABILITY_SUBJECT.AUTHENTICATED,
                },
            },
            {
                name: ROUTE.DASHBOARD_APPROVE_RECRUITERS,
                path: 'approve-recruiters',
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-approve-recruiters" */ '@/pages/TheDashboardFrame/views/ApproveRecruiters/ApproveRecruiters.vue'
                    ),
                meta: {
                    title: 'Approve recruiters',
                    abilityAction: ABILITY_ACTION.READ,
                    abilitySubject: ABILITY_SUBJECT.AUTHENTICATED,
                },
            },
            {
                name: ROUTE.DASHBOARD_JOBS,
                path: 'jobs',
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-jobs" */ '@/pages/TheDashboardFrame/views/JobList/JobList.vue'
                    ),
                meta: {
                    title: 'Jobs',
                    abilityAction: ABILITY_ACTION.READ,
                    abilitySubject: ABILITY_SUBJECT.AUTHENTICATED,
                },
            },
            {
                name: ROUTE.DASHBOARD_JOB,
                path: 'jobs/:id',
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-manage-job" */ '@/pages/TheDashboardFrame/views/ManageJob/ManageJob.vue'
                    ),
                meta: {
                    title: 'Job',
                    abilityAction: ABILITY_ACTION.READ,
                    abilitySubject: ABILITY_SUBJECT.AUTHENTICATED,
                },
            },
            {
                name: ROUTE.DASHBOARD_JOB_ADD,
                path: 'job-add',
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-job-builder" */ '@/pages/TheDashboardFrame/views/JobBuilder/JobBuilder.vue'
                    ),
                meta: {
                    title: 'Add job',
                    abilityAction: ABILITY_ACTION.READ,
                    abilitySubject: ABILITY_SUBJECT.AUTHENTICATED,
                },
            },
            {
                name: ROUTE.DASHBOARD_JOB_EDIT,
                path: 'job-edit',
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-job-builder" */ '@/pages/TheDashboardFrame/views/JobBuilder/JobBuilder.vue'
                    ),
                meta: {
                    title: 'Edit job',
                    abilityAction: ABILITY_ACTION.READ,
                    abilitySubject: ABILITY_SUBJECT.AUTHENTICATED,
                },
            },
            {
                name: ROUTE.DASHBOARD_CANDIDATES,
                path: 'candidates',
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-candidates" */ '@/pages/TheDashboardFrame/views/Candidates/Candidates.vue'
                    ),
                meta: {
                    title: 'Candidates',
                    abilityAction: ABILITY_ACTION.READ,
                    abilitySubject: ABILITY_SUBJECT.AUTHENTICATED,
                },
            },
            {
                name: ROUTE.DASHBOARD_ALL_CANDIDATES,
                path: 'all-candidates',
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-all-candidates" */ '@/pages/TheDashboardFrame/views/AllCandidates/AllCandidates.vue'
                    ),
                meta: {
                    title: 'All Candidates',
                    abilityAction: ABILITY_ACTION.READ,
                    abilitySubject: ABILITY_SUBJECT.AUTHENTICATED,
                },
            },
            {
                name: ROUTE.DASHBOARD_USERS,
                path: 'users',
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-company-users" */ '@/pages/TheDashboardFrame/views/Users/Users.vue'
                    ),
                meta: {
                    title: 'Users',
                    abilityAction: ABILITY_ACTION.READ,
                    abilitySubject: ABILITY_SUBJECT.AUTHENTICATED,
                },
            },
            {
                name: ROUTE.DASHBOARD_PROFILE,
                path: 'profile',
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-user-profile" */ '@/pages/TheDashboardFrame/views/UserProfile/UserProfile.vue'
                    ),
                meta: {
                    title: 'Your profile',
                    abilityAction: ABILITY_ACTION.READ,
                    abilitySubject: ABILITY_SUBJECT.AUTHENTICATED,
                },
            },
            {
                name: ROUTE.DASHBOARD_NEW_JOB_SPECIFICATIONS,
                path: 'new-job-specifications',
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-new-job-specifications" */ '@/pages/TheDashboardFrame/views/DashboardUserNewJobSpecifications/DashboardUserNewJobSpecifications.vue'
                    ),
                meta: {
                    title: 'New job criteria',
                    abilityAction: ABILITY_ACTION.READ,
                    abilitySubject: ABILITY_SUBJECT.AUTHENTICATED,
                },
            },
            {
                name: ROUTE.DASHBOARD_NEW_JOB_SPECIFICATION_DETAILS,
                path: 'new-job-specifications/:specificationUuid',
                component: () =>
                    import(
                        /* webpackChunkName: "dashboard-new-job-specifications" */ '@/pages/TheDashboardFrame/views/DashboardUserNewJobSpecificationDetails/DashboardUserNewJobSpecificationDetails.vue'
                    ),
                props: true,
                meta: {
                    title: 'New job spec',
                    abilityAction: ABILITY_ACTION.READ,
                    abilitySubject: ABILITY_SUBJECT.AUTHENTICATED,
                },
            },
            {
                name: ROUTE.DASHBOARD_INVITATIONS,
                path: 'invitations',
                component: () =>
                    import(
                        /* webpackChunkName: "invitations" */ '@/pages/Invitations/InvitationPage.vue'
                    ),
                meta: {
                    title: 'Invitations',
                    abilityAction: ABILITY_ACTION.READ,
                    abilitySubject: ABILITY_SUBJECT.AUTHENTICATED,
                },
            },
        ],
    },
]

import { API } from '@/api'
import { IJobApplication } from '@/types'
import { IRawJobApplication } from '../types'
import { getJobApplication } from '../utils'

export async function fetchJobApplications(jobId: number): Promise<IJobApplication[]> {
    const { data: applications } = await API.get<IRawJobApplication[]>(
        `jobs/${jobId}/applications`
    )

    return await Promise.all(applications.map(getJobApplication))
}

import { API } from '@/api'
import { IUuid } from '@/types'

export async function deletePreferredCity(
    specificationUuid: IUuid,
    cityId: number
): Promise<void> {
    await API.delete(
        `users/me/new-job-specifications/${specificationUuid}/preferred-cities/${cityId}`
    )
}

import dayjs from 'dayjs'
import { IUserReferredPerson } from '@/types'
import { IRawReferredPerson } from '../types'

export class ConverterReferredPeople {
    static fromRaw(rawReferredPeople: IRawReferredPerson[]): IUserReferredPerson[] {
        return rawReferredPeople.map((rawPerson) => ({
            name: rawPerson.name,
            email: rawPerson.email,
            jobId: rawPerson.job.id,
            jobTitle: rawPerson.job.title,
            status: rawPerson.status,
            date: dayjs(rawPerson.date).valueOf(),
            potencialReferralReward: rawPerson.potencialReferralReward,
        }))
    }
}

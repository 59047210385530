import dayjs from 'dayjs'
import { IRawUserNewJobSpecification } from '../types'
import { IUserNewJobSpecification } from '@/types'

export class ConverterNewJobSpecification {
    static fromRaw(rawSpecification: IRawUserNewJobSpecification): IUserNewJobSpecification {
        const { uuid, name, timestampCreated, timestampUpdated } = rawSpecification

        return {
            uuid,
            name,
            timestampCreated: dayjs(timestampCreated).valueOf(),
            timestampUpdated: dayjs(timestampUpdated).valueOf(),
        }
    }
}

import { API } from '@/api'
import { IUserSkill, IUuid } from '@/types'

export async function fetchPreferredUserSkills(
    specificationUuid: IUuid
): Promise<IUserSkill[]> {
    const { data } = await API.get<IUserSkill[]>(
        `users/me/new-job-specifications/${specificationUuid}/preferred-skills`
    )

    return data
}

import Vue from 'vue'
import { ACTION_DASHBOARD_USER_SKILLS, MUTATION_DASHBOARD_USER_SKILLS } from '@/enums'
import { REPOSITORY_USER_SKILLS } from '@/repositiories'
import {
    IStateDashboardUserSkills,
    IStateRoot,
    IUserSkillProfile,
    IUserSkillProfileConfig,
    IUserSkillProfileUpdateConfig,
    IUuid,
} from '@/types'
import { ActionTree, MutationTree } from 'vuex'

const state = (): IStateDashboardUserSkills => ({
    userSkills: undefined,
})

export const mutations: MutationTree<IStateDashboardUserSkills> = {
    [MUTATION_DASHBOARD_USER_SKILLS.SET_USER_SKILLS](state, userSkills: IUserSkillProfile[]) {
        state.userSkills = userSkills
    },

    [MUTATION_DASHBOARD_USER_SKILLS.UPDATE_USER_SKILL](
        state,
        payload: IUserSkillProfileUpdateConfig
    ) {
        const { uuid, ...props } = payload
        const skillIndex = state.userSkills?.findIndex((skill) => skill.uuid === uuid) ?? -1

        if (skillIndex < 0) return

        const updatedSkill = { ...state.userSkills![skillIndex], ...props }

        Vue.set(state.userSkills!, skillIndex, updatedSkill)
    },
}

export const actions: ActionTree<IStateDashboardUserSkills, IStateRoot> = {
    async [ACTION_DASHBOARD_USER_SKILLS.FETCH_AND_SET_USER_SKILLS]({ commit }): Promise<void> {
        try {
            const userSkills = await REPOSITORY_USER_SKILLS.fetchUserSkills()

            commit(MUTATION_DASHBOARD_USER_SKILLS.SET_USER_SKILLS, userSkills)
        } catch (error) {
            alert(error.data.message)
        }
    },

    async [ACTION_DASHBOARD_USER_SKILLS.CREATE_USER_SKILL](
        { state, commit },
        skillConfig: IUserSkillProfileConfig
    ): Promise<void> {
        try {
            const skill = await REPOSITORY_USER_SKILLS.createUserSkill(skillConfig)

            commit(MUTATION_DASHBOARD_USER_SKILLS.SET_USER_SKILLS, [
                ...(state.userSkills || []),
                skill,
            ])
        } catch (error) {
            alert(error.data.message)
        }
    },

    async [ACTION_DASHBOARD_USER_SKILLS.UPDATE_USER_SKILL](
        { commit },
        payload: IUserSkillProfileUpdateConfig
    ): Promise<void> {
        try {
            await REPOSITORY_USER_SKILLS.updateUserSkill(payload)

            commit(MUTATION_DASHBOARD_USER_SKILLS.UPDATE_USER_SKILL, payload)
        } catch (error) {
            alert(error.data.message)

            return Promise.reject(error)
        }
    },

    async [ACTION_DASHBOARD_USER_SKILLS.DELETE_USER_SKILL](
        { state, commit },
        skillUuid: IUuid
    ): Promise<void> {
        if (!state.userSkills) return

        const skills = [...state.userSkills]

        try {
            commit(
                MUTATION_DASHBOARD_USER_SKILLS.SET_USER_SKILLS,
                skills.filter(({ uuid }) => uuid !== skillUuid)
            )

            await REPOSITORY_USER_SKILLS.deleteUserSkill(skillUuid)
        } catch (error) {
            commit(MUTATION_DASHBOARD_USER_SKILLS.SET_USER_SKILLS, skills)
            alert(error.data.message)
        }
    },
}

export const dashboardUserSkills = {
    namespaced: true,
    state,
    mutations,
    actions,
}

import { ACTION_AUTH, ROUTE, STORE_MODULE } from '@/enums'
import { store } from '@/store/root'
import { router } from '@/router/router'
import { IStateAuth } from '@/types'

export async function signOutAndRedirect(): Promise<void> {
    const state = (store.state as unknown) as { [STORE_MODULE.AUTH]: IStateAuth }
    const userAccount = state[STORE_MODULE.AUTH].userAccount

    if (!userAccount) return

    await store.dispatch(`${STORE_MODULE.AUTH}/${ACTION_AUTH.SIGN_OUT}`)

    router.push({
        name: ROUTE.SIGN_IN,
        params: { previousRoutePath: router.currentRoute.path },
    })
}

import { API, createApiCache, createApiCacheStaticResourceGetter } from '@/api'
import { IUuid, IJobNoticePeriod } from '@/types'
import { fetchNoticePeriods } from './fetchNoticePeriods'

const cache = createApiCache<IUuid, IJobNoticePeriod>()
const { getCachedItem } = createApiCacheStaticResourceGetter<IUuid, IJobNoticePeriod>(
    cache,
    'id',
    fetchNoticePeriods
)

export async function fetchNoticePeriod(periodId: IUuid): Promise<IJobNoticePeriod> {
    const reason = await getCachedItem(periodId)

    if (!reason) {
        throw new Error(`Job notice period with id: "${periodId}" does not exist`)
    }

    return reason
}

import { MUTATION_JOB_SEARCH, ACTION_JOB_SEARCH } from '@/enums'
import { REPOSITORY_JOBS } from '@/repositiories'
import { IJob, IJobSearchFilters, IStateJobSearch, IStateRoot } from '@/types'
import { ActionTree, MutationTree } from 'vuex'

const state = (): IStateJobSearch => ({
    filters: undefined,
    jobs: undefined,
    hasFetchedAllJobs: false,
    areFiltersExpanded: false,
})

export const mutations: MutationTree<IStateJobSearch> = {
    [MUTATION_JOB_SEARCH.SET_FILTERS](state, filters: IJobSearchFilters) {
        state.filters = filters
    },

    [MUTATION_JOB_SEARCH.SET_JOBS](state, jobs?: IJob[]) {
        state.jobs = jobs
    },

    [MUTATION_JOB_SEARCH.ADD_JOBS](state, jobs: IJob[]) {
        state.jobs!.push(...jobs)
    },

    [MUTATION_JOB_SEARCH.SET_HAS_FETCHED_ALL_JOBS](state, hasFetchedAllJobs: boolean) {
        state.hasFetchedAllJobs = hasFetchedAllJobs
    },

    [MUTATION_JOB_SEARCH.SET_ARE_FILTERS_EXPANDED](state, areFiltersExpanded: boolean) {
        state.areFiltersExpanded = areFiltersExpanded
    },
}

export const actions: ActionTree<IStateJobSearch, IStateRoot> = {
    async [ACTION_JOB_SEARCH.FETCH_AND_SET_JOBS]({ state, commit }): Promise<void> {
        if (!state.filters) return

        try {
            const { items, hasMoreItems } = await REPOSITORY_JOBS.fetchJobs({
                filters: state.filters,
                offset: 0,
            })

            commit(MUTATION_JOB_SEARCH.SET_HAS_FETCHED_ALL_JOBS, !hasMoreItems)
            commit(MUTATION_JOB_SEARCH.SET_JOBS, items)
        } catch (error) {
            alert(error.data.errorMessage)
        }
    },

    async [ACTION_JOB_SEARCH.FETCH_AND_ADD_MORE_JOBS]({ state, commit }): Promise<void> {
        if (!state.filters || !state.jobs) return

        try {
            const { items, hasMoreItems } = await REPOSITORY_JOBS.fetchJobs({
                filters: state.filters,
                offset: state.jobs.length,
            })

            commit(MUTATION_JOB_SEARCH.SET_HAS_FETCHED_ALL_JOBS, !hasMoreItems)
            commit(MUTATION_JOB_SEARCH.ADD_JOBS, items)
        } catch (error) {
            alert(error.data.errorMessage)
        }
    },
}

export const jobSearch = {
    namespaced: true,
    state,
    mutations,
    actions,
}

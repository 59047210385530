import { ACTION_SIGN_UP, MUTATION_SIGN_UP } from '@/enums'
import { REPOSITORY_INVITATIONS_SIGN_UP } from '@/repositiories'
import { IInvitationSignUp, IStateRoot, IStateSignUp } from '@/types'
import { ActionTree, MutationTree } from 'vuex'

const INVITATION_UUID_LOCAL_STORAGE_KEY = 'sign-up-invitation-uuid'

const state = (): IStateSignUp => ({
    invitationUuid: localStorage.getItem(INVITATION_UUID_LOCAL_STORAGE_KEY) ?? undefined,
    invitation: undefined,
})

export const mutations: MutationTree<IStateSignUp> = {
    [MUTATION_SIGN_UP.SET_INVITATION_UUID](state, uuid?: string) {
        state.invitationUuid = uuid

        uuid
            ? localStorage.setItem(INVITATION_UUID_LOCAL_STORAGE_KEY, uuid)
            : localStorage.removeItem(INVITATION_UUID_LOCAL_STORAGE_KEY)
    },

    [MUTATION_SIGN_UP.SET_INVITATION](state, invitation?: IInvitationSignUp) {
        state.invitation = invitation
    },
}

export const actions: ActionTree<IStateSignUp, IStateRoot> = {
    async [ACTION_SIGN_UP.FETCH_AND_SET_INVITATION]({ commit }, invitationUuid): Promise<void> {
        try {
            const invitation = await REPOSITORY_INVITATIONS_SIGN_UP.fetchInvitationPending(
                invitationUuid
            )

            commit(MUTATION_SIGN_UP.SET_INVITATION, invitation)
        } catch (error) {
            return Promise.reject(error)
        }
    },
}

export const signUp = {
    namespaced: true,
    state,
    mutations,
    actions,
}

import { API } from '@/api'
import { IFile } from '@/types'

export async function createJobApplicationAttachments(
    jobId: number,
    jobApplicationId: number,
    files: IFile[]
): Promise<void> {
    await Promise.all(files.map((file) => createAttachment(jobId, jobApplicationId, file)))
}

async function createAttachment(jobId: number, jobApplicationId: number, file: IFile) {
    const { content, type, name, lastModified } = file
    const data = new FormData()
    const blob = new Blob([content], { type })

    data.append('cv', new File([blob], name, { lastModified: lastModified }))

    return await API.post(`jobs/${jobId}/applications/${jobApplicationId}/attachments`, data)
}

export enum ABILITY_ACTION {
    READ = 'Read',
    CREATE = 'Create',
    UPDATE = 'Update',
    DELETE = 'Delete',
}

export enum ABILITY_SUBJECT {
    PUBLIC = 'Public', // both authenticated and unauthenticated users
    UNAUTHENTICATED = 'Unauthenticated', // only unauthenticated users
    AUTHENTICATED = 'Authenticated', // only authenticated users

    JOBS = 'JOBS',
}

import { ACTION_DASHBOARD_USER_PROFILE, MUTATION_DASHBOARD_USER_PROFILE } from '@/enums'
import { REPOSITORY_USER_DETAILS } from '@/repositiories'
import { IStateDashboardUserProfile, IStateRoot, IUserDetails } from '@/types'
import { ActionTree, MutationTree } from 'vuex'

const state = (): IStateDashboardUserProfile => ({
    userDetails: undefined,
})

export const mutations: MutationTree<IStateDashboardUserProfile> = {
    [MUTATION_DASHBOARD_USER_PROFILE.SET_USER_DETAILS](state, userDetails: IUserDetails) {
        state.userDetails = userDetails
    },
}

export const actions: ActionTree<IStateDashboardUserProfile, IStateRoot> = {
    async [ACTION_DASHBOARD_USER_PROFILE.FETCH_AND_SET_USER_DETAILS]({
        commit,
    }): Promise<void> {
        try {
            const userDetails = await REPOSITORY_USER_DETAILS.fetchUserDetails()

            commit(MUTATION_DASHBOARD_USER_PROFILE.SET_USER_DETAILS, userDetails)
        } catch (error) {
            alert(error.data.message)
        }
    },

    async [ACTION_DASHBOARD_USER_PROFILE.UPDATE_USER_DETAILS](
        { commit },
        payload: Partial<IUserDetails>
    ): Promise<void> {
        try {
            const newDetails = await REPOSITORY_USER_DETAILS.updateUserDetails(payload)

            commit(MUTATION_DASHBOARD_USER_PROFILE.SET_USER_DETAILS, newDetails)
        } catch (error) {
            alert(error.data.message)
        }
    },
}

export const dashboardUserProfile = {
    namespaced: true,
    state,
    mutations,
    actions,
}

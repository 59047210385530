import {
    MUTATION_USER_NEW_JOB_SPECIFICATION_DETAILS,
    ACTION_DASHBOARD_USER_NEW_JOB_SPECIFICATION_DETAILS,
} from '@/enums'
import { REPOSITORY_USER_NEW_JOB_SPECIFICATION_DETAILS } from '@/repositiories'
import {
    ICity,
    ICompany,
    IStateDashboardUserNewJobSpecificationDetails,
    IStateRoot,
    IUserNewJobSpecificationConfig,
    IUserNewJobSpecificationDetails,
    IUserSkillConfig,
    IUuid,
} from '@/types'
import { ActionTree, MutationTree } from 'vuex'

const state = (): IStateDashboardUserNewJobSpecificationDetails => ({
    specification: undefined,
})

export const mutations: MutationTree<IStateDashboardUserNewJobSpecificationDetails> = {
    [MUTATION_USER_NEW_JOB_SPECIFICATION_DETAILS.SET_SPECIFICATION](
        state,
        specification: IUserNewJobSpecificationDetails
    ) {
        state.specification = specification
    },

    [MUTATION_USER_NEW_JOB_SPECIFICATION_DETAILS.UPDATE_SPECIFICATION](
        state,
        payload: Partial<IUserNewJobSpecificationDetails>
    ) {
        state.specification = { ...state.specification!, ...payload }
    },
}

export const actions: ActionTree<IStateDashboardUserNewJobSpecificationDetails, IStateRoot> = {
    async [ACTION_DASHBOARD_USER_NEW_JOB_SPECIFICATION_DETAILS.FETCH_AND_SET_SPECIFICATION](
        { commit },
        specificationUuid: IUuid
    ): Promise<void> {
        try {
            const specification = await REPOSITORY_USER_NEW_JOB_SPECIFICATION_DETAILS.fetchSpecification(
                specificationUuid
            )

            commit(MUTATION_USER_NEW_JOB_SPECIFICATION_DETAILS.SET_SPECIFICATION, specification)
        } catch (error) {
            alert(error.data.message)

            return Promise.reject(error)
        }
    },

    async [ACTION_DASHBOARD_USER_NEW_JOB_SPECIFICATION_DETAILS.UPDATE_SPECIFICATION](
        { state, commit },
        specificationConfig: Partial<IUserNewJobSpecificationConfig>
    ): Promise<void> {
        try {
            await REPOSITORY_USER_NEW_JOB_SPECIFICATION_DETAILS.updateSpecification(
                state.specification!.uuid,
                specificationConfig
            )

            commit(MUTATION_USER_NEW_JOB_SPECIFICATION_DETAILS.SET_SPECIFICATION, {
                ...state.specification!,
                ...specificationConfig,
            })
        } catch (error) {
            alert(error.data.message)

            return Promise.reject(error)
        }
    },

    async [ACTION_DASHBOARD_USER_NEW_JOB_SPECIFICATION_DETAILS.CREATE_BLACKLISTED_COMPANY](
        { state, commit },
        company: ICompany
    ): Promise<void> {
        try {
            await REPOSITORY_USER_NEW_JOB_SPECIFICATION_DETAILS.createBlacklistedCompany(
                state.specification!.uuid,
                company
            )

            const blacklistedCompanies = [...state.specification!.blacklistedCompanies, company]

            commit(MUTATION_USER_NEW_JOB_SPECIFICATION_DETAILS.UPDATE_SPECIFICATION, {
                blacklistedCompanies,
            })
        } catch (error) {
            alert(error.data.message)

            return Promise.reject(error)
        }
    },

    async [ACTION_DASHBOARD_USER_NEW_JOB_SPECIFICATION_DETAILS.DELETE_BLACKLISTED_COMPANY](
        { state, commit },
        companyId: number
    ): Promise<void> {
        const blacklistedCompanies = state.specification!.blacklistedCompanies

        try {
            commit(MUTATION_USER_NEW_JOB_SPECIFICATION_DETAILS.UPDATE_SPECIFICATION, {
                blacklistedCompanies: blacklistedCompanies.filter(({ id }) => id !== companyId),
            })

            await REPOSITORY_USER_NEW_JOB_SPECIFICATION_DETAILS.deleteBlacklistedCompany(
                state.specification!.uuid,
                companyId
            )
        } catch (error) {
            commit(MUTATION_USER_NEW_JOB_SPECIFICATION_DETAILS.UPDATE_SPECIFICATION, {
                blacklistedCompanies,
            })
            alert(error.data.message)

            return Promise.reject(error)
        }
    },

    async [ACTION_DASHBOARD_USER_NEW_JOB_SPECIFICATION_DETAILS.CREATE_PREFERRED_COMPANY](
        { state, commit },
        company: ICompany
    ): Promise<void> {
        try {
            await REPOSITORY_USER_NEW_JOB_SPECIFICATION_DETAILS.createPreferredCompany(
                state.specification!.uuid,
                company
            )

            const preferredCompanies = [...state.specification!.preferredCompanies, company]

            commit(MUTATION_USER_NEW_JOB_SPECIFICATION_DETAILS.UPDATE_SPECIFICATION, {
                preferredCompanies,
            })
        } catch (error) {
            alert(error.data.message)

            return Promise.reject(error)
        }
    },

    async [ACTION_DASHBOARD_USER_NEW_JOB_SPECIFICATION_DETAILS.DELETE_PREFERRED_COMPANY](
        { state, commit },
        companyId: number
    ): Promise<void> {
        const preferredCompanies = state.specification!.preferredCompanies

        try {
            commit(MUTATION_USER_NEW_JOB_SPECIFICATION_DETAILS.UPDATE_SPECIFICATION, {
                preferredCompanies: preferredCompanies.filter(({ id }) => id !== companyId),
            })

            await REPOSITORY_USER_NEW_JOB_SPECIFICATION_DETAILS.deletePreferredCompany(
                state.specification!.uuid,
                companyId
            )
        } catch (error) {
            commit(MUTATION_USER_NEW_JOB_SPECIFICATION_DETAILS.UPDATE_SPECIFICATION, {
                preferredCompanies,
            })
            alert(error.data.message)

            return Promise.reject(error)
        }
    },

    async [ACTION_DASHBOARD_USER_NEW_JOB_SPECIFICATION_DETAILS.CREATE_PREFERRED_CITY](
        { state, commit },
        city: ICity
    ): Promise<void> {
        try {
            await REPOSITORY_USER_NEW_JOB_SPECIFICATION_DETAILS.createPreferredCity(
                state.specification!.uuid,
                city
            )

            const preferredCities = [...state.specification!.preferredCities, city]

            commit(MUTATION_USER_NEW_JOB_SPECIFICATION_DETAILS.UPDATE_SPECIFICATION, {
                preferredCities,
            })
        } catch (error) {
            alert(error.data.message)

            return Promise.reject(error)
        }
    },

    async [ACTION_DASHBOARD_USER_NEW_JOB_SPECIFICATION_DETAILS.DELETE_PREFERRED_CITY](
        { state, commit },
        cityId: number
    ): Promise<void> {
        const preferredCities = state.specification!.preferredCities

        try {
            commit(MUTATION_USER_NEW_JOB_SPECIFICATION_DETAILS.UPDATE_SPECIFICATION, {
                preferredCities: preferredCities.filter(({ id }) => id !== cityId),
            })

            await REPOSITORY_USER_NEW_JOB_SPECIFICATION_DETAILS.deletePreferredCity(
                state.specification!.uuid,
                cityId
            )
        } catch (error) {
            commit(MUTATION_USER_NEW_JOB_SPECIFICATION_DETAILS.UPDATE_SPECIFICATION, {
                preferredCities,
            })
            alert(error.data.message)

            return Promise.reject(error)
        }
    },

    async [ACTION_DASHBOARD_USER_NEW_JOB_SPECIFICATION_DETAILS.CREATE_PREFERRED_USER_SKILL](
        { state, commit },
        userSkillConfig: IUserSkillConfig
    ): Promise<void> {
        try {
            const userSkill = await REPOSITORY_USER_NEW_JOB_SPECIFICATION_DETAILS.createPreferredUserSkill(
                state.specification!.uuid,
                userSkillConfig
            )

            const preferredSkills = [...state.specification!.preferredSkills, userSkill]

            commit(MUTATION_USER_NEW_JOB_SPECIFICATION_DETAILS.UPDATE_SPECIFICATION, {
                preferredSkills,
            })
        } catch (error) {
            alert(error.data.message)

            return Promise.reject(error)
        }
    },

    async [ACTION_DASHBOARD_USER_NEW_JOB_SPECIFICATION_DETAILS.DELETE_PREFERRED_USER_SKILL](
        { state, commit },
        userSkillUuid: IUuid
    ): Promise<void> {
        const preferredSkills = state.specification!.preferredSkills

        try {
            commit(MUTATION_USER_NEW_JOB_SPECIFICATION_DETAILS.UPDATE_SPECIFICATION, {
                preferredSkills: preferredSkills.filter(({ uuid }) => uuid !== userSkillUuid),
            })

            await REPOSITORY_USER_NEW_JOB_SPECIFICATION_DETAILS.deletePreferredUserSkill(
                state.specification!.uuid,
                userSkillUuid
            )
        } catch (error) {
            commit(MUTATION_USER_NEW_JOB_SPECIFICATION_DETAILS.UPDATE_SPECIFICATION, {
                preferredSkills,
            })
            alert(error.data.message)

            return Promise.reject(error)
        }
    },
}

export const dashboardUserNewJobSpecificationDetails = {
    namespaced: true,
    state,
    mutations,
    actions,
}

import { createEnumFromStringArray } from '@/utils'

export const ROUTE = createEnumFromStringArray([
    'COMPANY_SETUP',
    'DASHBOARD_APPROVE_RECRUITERS',
    'DASHBOARD_CANDIDATES',
    'DASHBOARD_ALL_CANDIDATES',
    'DASHBOARD_COMPANY_DEPARTMENTS',
    'DASHBOARD_COMPANY',
    'DASHBOARD_INVITATIONS',
    'DASHBOARD_JOB_ADD',
    'DASHBOARD_JOB_EDIT',
    'DASHBOARD_JOB',
    'DASHBOARD_JOBS',
    'DASHBOARD_NETWORK',
    'DASHBOARD_PROFILE',
    'DASHBOARD_RECRUITMENT_PROCESS',
    'DASHBOARD_REFERRED_PEOPLE',
    'DASHBOARD_RESPONSE_RATE',
    'DASHBOARD_USERS',
    'DASHBOARD_NEW_JOB_SPECIFICATIONS',
    'DASHBOARD_NEW_JOB_SPECIFICATION_DETAILS',
    'DASHBOARD',
    'JOB_DETAILS',
    'JOB_SEARCH',
    'JOB_SHOWCASE',
    'JOBS_BY_COMPANY',
    'PASSWORD_RECOVERY',
    'PRIVACY_POLICY',
    'SIGN_IN',
    'SIGN_UP_CONFIRM',
    'START_FOR_CANDIDATE',
    'START_FOR_NETWORKER',
    'START_FOR_RECRUITER',
    'START',
    'SUBSCRIPTION_CONFIRM',
    'DASHBOARD_COMPANY_ADDRESSES',
])

export type ROUTE = keyof typeof ROUTE

import { API } from '@/api'
import { IUuid } from '@/types'

export async function deletePreferredUserSkill(
    specificationUuid: IUuid,
    userSkillUuid: IUuid
): Promise<void> {
    await API.delete(
        `users/me/new-job-specifications/${specificationUuid}/preferred-skills/${userSkillUuid}`
    )
}

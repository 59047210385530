export enum ACTION_AUTH {
    SIGN_IN_FROM_CREDENTIALS = 'SignInFromCredentials',
    SIGN_IN_FROM_TOKEN = 'SignInFromToken',
    SIGN_IN = 'SignIn',
    SIGN_OUT = 'SignOut',
    REFRESH = 'Refresh',
}

export enum ACTION_SIGN_UP {
    FETCH_AND_SET_INVITATION = 'FETCH_AND_SET_INVITATION',
}

export enum ACTION_ONBOARDING {
    FETCH_AND_SET_ONBOARDING_STATE = 'FetchAndSetOnboardingState',
}

export enum ACTION_JOB_SEARCH {
    FETCH_AND_SET_JOBS = 'FetchAndSetJobs',
    FETCH_AND_ADD_MORE_JOBS = 'FetchAndAddMoreJobs',
}

export enum ACTION_DASHBOARD_JOB_APPLICATIONS {
    FETCH_AND_SET_APPLICATIONS = 'FetchAndSetApplications',
    CALL_APPLICATION_ACTION = 'CallApplicationAction',
}

export enum ACTION_DASHBOARD_USER_PROFILE {
    FETCH_AND_SET_USER_DETAILS = 'FetchAndSetUserDetails',
    UPDATE_USER_DETAILS = 'UpdateUserDetails',
}

export enum ACTION_DASHBOARD_USER_SKILLS {
    FETCH_AND_SET_USER_SKILLS = 'FetchAndSetUserSkills',
    CREATE_USER_SKILL = 'CreateUserSkill',
    UPDATE_USER_SKILL = 'UpdateUserSkill',
    DELETE_USER_SKILL = 'DeleteUserSkill',
}

export enum ACTION_DASHBOARD_USER_NEW_JOB_SPECIFICATIONS {
    FETCH_AND_SET_SPECIFICATIONS = 'FetchAndSetSpecifications',
    CREATE_SPECIFICATION = 'CreateSpecification',
    DELETE_SPECIFICATION = 'DeleteSpecification',
}

export enum ACTION_DASHBOARD_USER_NEW_JOB_SPECIFICATION_DETAILS {
    FETCH_AND_SET_SPECIFICATION = 'FetchAndSetSpecification',
    UPDATE_SPECIFICATION = 'UpdateSpecification',
    CREATE_BLACKLISTED_COMPANY = 'CreateBlacklistedCompany',
    DELETE_BLACKLISTED_COMPANY = 'DeleteBlacklistedCompany',
    CREATE_PREFERRED_COMPANY = 'CreatePreferredCompany',
    DELETE_PREFERRED_COMPANY = 'DeletePreferredCompany',
    CREATE_PREFERRED_CITY = 'CreatePreferredCity',
    DELETE_PREFERRED_CITY = 'DeletePreferredCity',
    CREATE_PREFERRED_USER_SKILL = 'CreatePreferredUserSkill',
    DELETE_PREFERRED_USER_SKILL = 'DeletePreferredUserSkill',
}

export enum ACTION_DASHBOARD_REFERRED_PEOPLE {
    FETCH_AND_SET_REFERRED_PEOPLE = 'FetchAndSetReferredPeople',
}

export enum ACTION_DASHBOARD_COMPANIES {
    FETCH_AND_SET_COMPANIES = 'FetchAndSetCompanies',
}

export enum ACTION_DASHBOARD_COMPANY_ADDRESSES {
    FETCH_AND_SET_COMPANY_ADDRESSES = 'FetchAndSetCompanyAddresses',
    ADD_COMPANY_ADDRESS = 'AddCompanyAddress',
    CHANGE_HEADQUARTERS_COMPANY_ADDRESSES = 'ChangeHeadquartersCompanyAddresses',
    DELETE_COMPANY_ADDRESS = 'DeleteCompanyAddress',
}

export enum ACTION_DASHBOARD_PROCESS {
    FETCH_AND_SET_PROCESSES = 'FetchAndSetProcesses',
    ADD_PROCESS = 'AddProcess',
    CLONE_PROCESS = 'CloneProcess',
    UPDATE_PROCESS = 'UpdateProcess',
    DELETE_PROCESS = 'DeleteProcess',
}

export enum ACTION_DASHBOARD_CANDIDATES {
    FETCH_AND_SET_CANDIDATES = 'FetchAndSetCandidates',
}

import { MutationTree, ActionTree } from 'vuex'
import { ACTION_DASHBOARD_PROCESS, MUTATION_DASHBOARD_PROCESS } from '@/enums'
import { IStateDashboardProcess, IStateRoot } from '@/types'
import { REPOSITORY_PROCESS } from '@/repositiories'
import { IProcess, IProcessStage } from '@/types'
import Vue from 'vue'

const state = (): IStateDashboardProcess => ({
    processes: undefined,
    process: undefined,
})

export const mutations: MutationTree<IStateDashboardProcess> = {
    [MUTATION_DASHBOARD_PROCESS.SET_PROCESSES](state, processes: IProcess[]) {
        state.processes = processes
    },

    [MUTATION_DASHBOARD_PROCESS.ADD_PROCESSES](state, process: IProcess) {
        state.processes?.push(process)
    },
    [MUTATION_DASHBOARD_PROCESS.UPDATE_PROCESS](state, process: IProcess) {
        if (!state.processes) return
        const index = state.processes.findIndex((item) => {
            return item.id === process.id
        })
        if (index > -1) Vue.set(state.processes, index, process)
    },
    [MUTATION_DASHBOARD_PROCESS.DELETE_PROCESS](state, processId: number) {
        if (!state.processes) return
        state.processes = state.processes.filter((x: IProcess) => x.id !== processId)
    },

    [MUTATION_DASHBOARD_PROCESS.SET_PROCESS](state, process: IProcess) {
        state.process = process
    },

    [MUTATION_DASHBOARD_PROCESS.ADD_STAGE](state, stage: IProcessStage) {
        if (state.process) {
            let process = { ...state.process, stages: state.process?.stages } as IProcess
            stage.stage = process.stages.length + 1
            process.stages.push({ ...stage })
            state.process = process
        }
    },

    [MUTATION_DASHBOARD_PROCESS.DELETE_STAGE](state, stage: IProcessStage) {
        if (state.process) {
            let process = { ...state.process!, stages: state.process?.stages } as IProcess
            process.stages = process.stages.filter(
                (x: IProcessStage) => x.stage !== stage.stage && x.name !== stage.name
            )
            state.process = process
        }
    },
}

export const actions: ActionTree<IStateDashboardProcess, IStateRoot> = {
    async [ACTION_DASHBOARD_PROCESS.FETCH_AND_SET_PROCESSES](
        { commit },
        processId: number
    ): Promise<void> {
        try {
            const processes = await REPOSITORY_PROCESS.getProcesses(processId)

            commit(MUTATION_DASHBOARD_PROCESS.SET_PROCESSES, processes)
        } catch (error) {
            alert(error.data.message)
        }
    },

    async [ACTION_DASHBOARD_PROCESS.ADD_PROCESS]({ commit }, process: IProcess): Promise<void> {
        try {
            const newProcess = await REPOSITORY_PROCESS.addProcess(process)

            commit(MUTATION_DASHBOARD_PROCESS.ADD_PROCESSES, newProcess)
        } catch (error) {
            alert(error.data.message)
        }
    },

    async [ACTION_DASHBOARD_PROCESS.CLONE_PROCESS](
        { commit },
        processId: number
    ): Promise<void> {
        try {
            const clonedProcess = await REPOSITORY_PROCESS.cloneProcess(processId)

            commit(MUTATION_DASHBOARD_PROCESS.ADD_PROCESSES, clonedProcess)
        } catch (error) {
            alert(error.data.message)
        }
    },

    async [ACTION_DASHBOARD_PROCESS.UPDATE_PROCESS](
        { commit },
        process: IProcess
    ): Promise<void> {
        try {
            const updatedProcess = await REPOSITORY_PROCESS.updateProcess(process)

            commit(MUTATION_DASHBOARD_PROCESS.UPDATE_PROCESS, updatedProcess)
        } catch (error) {
            alert(error.data.message)
        }
    },

    async [ACTION_DASHBOARD_PROCESS.DELETE_PROCESS](
        { commit },
        processId: number
    ): Promise<void> {
        try {
            await REPOSITORY_PROCESS.deleteProcess(processId)

            commit(MUTATION_DASHBOARD_PROCESS.DELETE_PROCESS, processId)
        } catch (error) {
            alert(error.data.message)
        }
    },
}

export const dashbaordJobRecruitmentProcess = {
    namespaced: true,
    state,
    mutations,
    actions,
}

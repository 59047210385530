import { store } from '@/store/root'
import { ACTION_AUTH, TOKEN_LOCAL_STORAGE_KEY, STORE_MODULE } from '@/enums'
import { IStateAuth } from '@/types'

export const signInIfNotSignedAndValidTokenStored = async (): Promise<void> => {
    const state = (store.state as unknown) as { [STORE_MODULE.AUTH]: IStateAuth }
    const userAccount = state[STORE_MODULE.AUTH].userAccount
    const token = localStorage.getItem(TOKEN_LOCAL_STORAGE_KEY)

    if (!!userAccount || !token) return

    try {
        await store.dispatch(`${STORE_MODULE.AUTH}/${ACTION_AUTH.SIGN_IN_FROM_TOKEN}`, token)
    } catch (error) {
        localStorage.removeItem(TOKEN_LOCAL_STORAGE_KEY)

        throw error
    }
}

import { API } from '@/api'
import { API_LIST_REQUEST_LIMIT_AUTOCOMPLETE } from '@/enums'
import { IListRequestParams, IListRequestResult, ICity } from '@/types'

export async function fetchJobCities({
    offset = 0,
    limit = API_LIST_REQUEST_LIMIT_AUTOCOMPLETE,
    searchText = '',
}: IListRequestParams): Promise<IListRequestResult<ICity>> {
    const params = { offset, limit, searchText }
    const { data } = await API.get<ICity[]>('jobs/search/cities', { params })

    return {
        items: data,
        hasMoreItems: data.length === limit,
    }
}

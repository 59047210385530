import { API } from '@/api'
import { IUserReferredPerson } from '@/types'
import { ConverterReferredPeople } from '../converters'
import { IRawReferredPerson } from '../types'

export async function fetchReferredPeople(userId: number): Promise<IUserReferredPerson[]> {
    const { data } = await API.get<IRawReferredPerson[]>(`/profile/referrals-list/${userId}`)

    return ConverterReferredPeople.fromRaw(data)
}

import { ACTION_DASHBOARD_JOB_APPLICATIONS, MUTATION_DASHBOARD_JOB_APPLICATIONS } from '@/enums'
import { REPOSITORY_JOB_APPLICATIONS } from '@/repositiories'
import {
    IJobApplication,
    IJobApplicationActionCallPayload,
    IStateDashboardJobApplications,
    IStateRoot,
} from '@/types'
import { ActionTree, MutationTree } from 'vuex'

const state = (): IStateDashboardJobApplications => ({
    applications: undefined,
})

export const mutations: MutationTree<IStateDashboardJobApplications> = {
    [MUTATION_DASHBOARD_JOB_APPLICATIONS.SET_APPLICATIONS](
        state,
        applications: IJobApplication[]
    ) {
        state.applications = applications
    },
}

export const actions: ActionTree<IStateDashboardJobApplications, IStateRoot> = {
    async [ACTION_DASHBOARD_JOB_APPLICATIONS.FETCH_AND_SET_APPLICATIONS](
        { commit },
        jobId: number
    ): Promise<void> {
        try {
            const applications = await REPOSITORY_JOB_APPLICATIONS.fetchJobApplications(jobId)

            commit(MUTATION_DASHBOARD_JOB_APPLICATIONS.SET_APPLICATIONS, applications)
        } catch (error) {
            alert(error.data.message)
        }
    },

    async [ACTION_DASHBOARD_JOB_APPLICATIONS.CALL_APPLICATION_ACTION](
        { dispatch },
        payload: IJobApplicationActionCallPayload
    ): Promise<void> {
        try {
            await REPOSITORY_JOB_APPLICATIONS.callJobApplicationAction(payload)
            await dispatch(
                ACTION_DASHBOARD_JOB_APPLICATIONS.FETCH_AND_SET_APPLICATIONS,
                payload.jobId
            )
        } catch (error) {
            alert(error.data.message)
        }
    },
}

export const dashboardJobApplications = {
    namespaced: true,
    state,
    mutations,
    actions,
}

import Vue from 'vue'

const state = {
    referralTraits: [],
}

export const getters = {
    referralTraits(...[state, , , rootGetters]) {
        return state.referralTraits
    },
}

export const actions = {
    async fetchTraits({ commit, rootState }) {
        const response = await rootState.backend.get('/referral/traits/')
        commit('setTraits', { traits: response.data })
    },
}

export const mutations = {
    setTraits(state, { traits }) {
        Vue.set(state, 'referralTraits', traits)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}

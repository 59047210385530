import { Route } from 'vue-router'

export function setDocumentTitle(to: Route) {
    // AfterEach is called before DOM change, so `window.location.href` is not equal
    // to `to.fullPath`, timeout is temporary hack https://github.com/vuejs/vue-router/issues/2079
    // suprisingly it works in firefox without timeout (technically it shouldn't)
    setTimeout(() => {
        setTitle(to.meta.title)
    }, 0)
}

function setTitle(title?: string): void {
    const baseTitle = 'Refair.me'

    document.title = title ? `${title} | ${baseTitle}` : baseTitle
}

import { API } from '@/api'
import { IUserSkill, IUserSkillConfig, IUuid } from '@/types'

export async function createPreferredUserSkill(
    specificationUuid: IUuid,
    userSkillConfig: IUserSkillConfig
): Promise<IUserSkill> {
    const { data } = await API.post<IUserSkill>(
        `users/me/new-job-specifications/${specificationUuid}/preferred-skills`,
        userSkillConfig
    )

    return data
}

import { AbilityBuilder, Ability } from '@casl/ability'
import { ABILITY_ACTION, ABILITY_SUBJECT, PERMISSION } from '@/enums'
import { ABILITIES_BY_PERMISSIONS } from './abilitiesByPermissions'

type AppAbility = Ability<[ABILITY_ACTION, ABILITY_SUBJECT]>

function getAbilityRulesForUnauthenticated() {
    const { rules, can } = new AbilityBuilder<AppAbility>()

    can(ABILITY_ACTION.READ, ABILITY_SUBJECT.PUBLIC)
    can(ABILITY_ACTION.READ, ABILITY_SUBJECT.UNAUTHENTICATED)

    return rules
}

function getAbilityRulesForAuthenticated(userPermissions: PERMISSION[]) {
    const { rules, can } = new AbilityBuilder<AppAbility>()

    userPermissions.forEach((permission: PERMISSION): void => {
        const ability = ABILITIES_BY_PERMISSIONS[permission]

        if (ability) can(...ability)
    })

    can(ABILITY_ACTION.READ, ABILITY_SUBJECT.PUBLIC)
    can(ABILITY_ACTION.READ, ABILITY_SUBJECT.AUTHENTICATED)

    return rules
}

export const ability: Ability = new Ability<[ABILITY_ACTION, ABILITY_SUBJECT]>(
    getAbilityRulesForUnauthenticated()
)

export function defineAbilitiesForUserAccount(userPermissions?: PERMISSION[]): void {
    const newRules = userPermissions
        ? getAbilityRulesForAuthenticated(userPermissions)
        : getAbilityRulesForUnauthenticated()

    ability.update(newRules)
}

export function addAbilityForUserAccount(userPermission: PERMISSION): void {
    const [actions, subject] = ABILITIES_BY_PERMISSIONS[userPermission]

    ability.update([...ability.rules, { actions, subject }])
}

export function removeAbilityForUserAccount(userPermission: PERMISSION): void {
    const [actions, subject] = ABILITIES_BY_PERMISSIONS[userPermission]

    ability.update([...ability.rules, { actions, subject, inverted: true }])
}

import { ABILITY_ACTION, ABILITY_SUBJECT, ROUTE } from '@/enums'
import { IRouteConfig } from '../types'

export const routesPublic: IRouteConfig[] = [
    {
        name: ROUTE.JOB_SEARCH,
        path: '/results',
        component: () =>
            import(
                /* webpackChunkName: "job-search" */ '@/pages/TheFrontFrame/views/JobSearch/JobSearch.vue'
            ),
        meta: {
            title: 'Job search result',
            abilityAction: ABILITY_ACTION.READ,
            abilitySubject: ABILITY_SUBJECT.PUBLIC,
        },
    },
    {
        name: ROUTE.JOB_DETAILS,
        path: '/job/:jobId',
        component: () =>
            import(
                /* webpackChunkName: "job-details" */ '@/pages/TheFrontFrame/views/JobDetails/JobDetails.vue'
            ),
        props: true,
        meta: {
            title: 'Job details',
            abilityAction: ABILITY_ACTION.READ,
            abilitySubject: ABILITY_SUBJECT.PUBLIC,
        },
    },
    {
        name: ROUTE.JOB_SHOWCASE,
        path: '/job-showcase',
        component: () =>
            import(
                /* webpackChunkName: "job-showcase" */ '@/pages/TheFrontFrame/views/AddJobShowcase/AddJobShowcase.vue'
            ),
        meta: {
            title: 'Job showcase',
            abilityAction: ABILITY_ACTION.READ,
            abilitySubject: ABILITY_SUBJECT.PUBLIC,
        },
    },
    {
        name: ROUTE.JOBS_BY_COMPANY,
        path: '/company/:id',
        component: () =>
            import(
                /* webpackChunkName: "jobs-by-company" */ '@/pages/TheFrontFrame/views/JobsByCompany/JobsByCompany.vue'
            ),
        meta: {
            title: 'Company jobs',
            abilityAction: ABILITY_ACTION.READ,
            abilitySubject: ABILITY_SUBJECT.PUBLIC,
        },
    },
    {
        name: ROUTE.PRIVACY_POLICY,
        path: '/privacypolicy',
        component: () =>
            import(
                /* webpackChunkName: "privacy-policy" */ '@/pages/TheFrontFrame/views/PrivacyPolicy/PrivacyPolicy.vue'
            ),
        meta: {
            title: 'Privacy policy',
            abilityAction: ABILITY_ACTION.READ,
            abilitySubject: ABILITY_SUBJECT.PUBLIC,
        },
    },
    // TODO
    {
        name: ROUTE.COMPANY_SETUP,
        path: '/company-setup',
        component: () =>
            import(
                /* webpackChunkName: "company-setup" */ '@/pages/TheFrontFrame/views/CompanySetup/CompanySetup.vue'
            ),
        meta: {
            title: 'Company setup',
            abilityAction: ABILITY_ACTION.READ,
            abilitySubject: ABILITY_SUBJECT.AUTHENTICATED,
        },
    },
]

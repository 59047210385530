import { MutationTree, ActionTree } from 'vuex'
import { MUTATION_ONBOARDING, ACTION_ONBOARDING } from '@/enums'
import { IStateOnboarding, IStateRoot, IUserOnboardingState } from '@/types'
import { REPOSITORY_USER_DETAILS } from '@/repositiories'

const getInitialOnboardingState = (): IUserOnboardingState => ({
    isCompanySetupCompleted: false,
})

const state = (): IStateOnboarding => ({
    onboardingState: getInitialOnboardingState(),
})

export const mutations: MutationTree<IStateOnboarding> = {
    [MUTATION_ONBOARDING.SET_ONBOARDING_STATE](state, onboardingState: IUserOnboardingState) {
        state.onboardingState = onboardingState
    },

    [MUTATION_ONBOARDING.RESET_ONBOARDING_STATE](state) {
        state.onboardingState = getInitialOnboardingState()
    },
}

export const actions: ActionTree<IStateOnboarding, IStateRoot> = {
    async [ACTION_ONBOARDING.FETCH_AND_SET_ONBOARDING_STATE]({ commit }): Promise<void> {
        try {
            const onboardingState = await REPOSITORY_USER_DETAILS.fetchUserOnboardingState()

            commit(MUTATION_ONBOARDING.SET_ONBOARDING_STATE, onboardingState)
        } catch (error) {
            commit(MUTATION_ONBOARDING.RESET_ONBOARDING_STATE)

            alert(error.data.message)
        }
    },
}

export const onboarding = {
    namespaced: true,
    state,
    mutations,
    actions,
}

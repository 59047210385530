import { API } from '@/api'
import { IUuid, IJobApplicationDetails } from '@/types'
import { IRawJobApplication } from '../types'
import { getJobApplication } from '../utils'
import { fetchJobApplicationAttachments } from './fetchJobApplicationAttachments'

export async function fetchJobApplicationDetails(
    jobId: number,
    jobApplicationId: number
): Promise<IJobApplicationDetails> {
    const { data } = await API.get<IRawJobApplication>(
        `jobs/${jobId}/applications/${jobApplicationId}`
    )

    return await getDetails(data)
}

async function getDetails(rawApplication: IRawJobApplication): Promise<IJobApplicationDetails> {
    const { actions = [] } = rawApplication
    const [jobApplication, attachments] = await Promise.all([
        getJobApplication(rawApplication),
        fetchJobApplicationAttachments(rawApplication.jobId, rawApplication.id),
    ])

    return {
        ...jobApplication,
        attachments,
        actions,
    }
}

import { API } from '@/api'
import { ICompany, IUuid } from '@/types'

export async function fetchBlacklistedCompanies(specificationUuid: IUuid): Promise<ICompany[]> {
    const { data } = await API.get<ICompany[]>(
        `users/me/new-job-specifications/${specificationUuid}/blacklisted-companies`
    )

    return data
}

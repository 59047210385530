import { API } from '@/api'
import { ICompanyAddress } from '@/types'

export async function getAddresses(addressId: number): Promise<ICompanyAddress[]> {
    const { data } = await API.get<ICompanyAddress[]>(`/company/addresses/${addressId}`)
    return data
}

export async function addAddress(payload: ICompanyAddress): Promise<ICompanyAddress> {
    const { data } = await API.post<ICompanyAddress>('/company/address/add', payload)
    return data
}

export async function changeHeadquarters(
    payload: ICompanyAddress[]
): Promise<ICompanyAddress[]> {
    const { data } = await API.put<ICompanyAddress[]>(
        '/company/address/changeHeadquarters',
        payload
    )

    return data
}

export async function deleteAddress(addressId: number): Promise<void> {
    await API.delete(`/company/address/delete/${addressId}`)
}

import { API } from '@/api'
import { IUuid, IInvitationSignUp } from '@/types'

export async function fetchInvitationPending(
    invitationUuid: IUuid
): Promise<IInvitationSignUp> {
    const { data } = await API.get<Omit<IInvitationSignUp, 'uuid'>>(
        `invitations/sign-up/pending/${invitationUuid}`
    )

    return { uuid: invitationUuid, ...data }
}

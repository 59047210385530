import { API } from '@/api'
import { IUserNewJobSpecification } from '@/types'
import { ConverterNewJobSpecification } from '../../converters'
import { IRawUserNewJobSpecification } from '../../types'

export async function fetchSpecifications(): Promise<IUserNewJobSpecification[]> {
    const { data: rawSpecifications } = await API.get<IRawUserNewJobSpecification[]>(
        `users/me/new-job-specifications`
    )

    return rawSpecifications.map(ConverterNewJobSpecification.fromRaw)
}

import { createApiCache, createApiCacheStaticResourceGetter } from '@/api'
import { IUuid, IJobRecruitmentAvailability } from '@/types'
import { fetchJobRecruitmentAvailabilities } from './fetchJobRecruitmentAvailabilities'

const cache = createApiCache<IUuid, IJobRecruitmentAvailability>()
const { getCachedItem } = createApiCacheStaticResourceGetter<
    IUuid,
    IJobRecruitmentAvailability
>(cache, 'id', fetchJobRecruitmentAvailabilities)

export async function fetchJobRecruitmentAvailability(
    availabilityId: IUuid
): Promise<IJobRecruitmentAvailability> {
    const availability = await getCachedItem(availabilityId)

    if (!availability) {
        throw new Error(
            `Job recruitment availability with id: "${availabilityId}" does not exist`
        )
    }

    return availability
}

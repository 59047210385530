import { API } from '@/api'
import { IUserNewJobSpecificationConfig, IUserNewJobSpecificationDetails } from '@/types'
import { ConverterNewJobSpecificationDetails } from '../converters'
import { IRawUserNewJobSpecificationDetails } from '../types'

export async function createSpecification(
    specificationConfig: Pick<IUserNewJobSpecificationConfig, 'name'>
): Promise<IUserNewJobSpecificationDetails> {
    const { data } = await API.post<IRawUserNewJobSpecificationDetails>(
        `users/me/new-job-specifications`,
        specificationConfig
    )

    return await ConverterNewJobSpecificationDetails.fromRaw(data)
}

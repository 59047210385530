import Vue from 'vue'
import VueRouter from 'vue-router'
import { routesPublic, routesUnauthenticated, routesAuthenticated } from './routes'
import { setDocumentTitle, navigationGuard } from './utils'
import { router } from './router'
import { ROUTE } from '@/enums'
import { IRouteConfig } from './types'

Vue.use(VueRouter)

const routes: IRouteConfig[] = [
    // FIXME: remove wrapper (use layout)
    {
        path: '',
        component: () =>
            import(
                /* webpackChunkName: "the-front-frame" */ '@/pages/TheFrontFrame/TheFrontFrame.vue'
            ),
        children: [...routesPublic, ...routesUnauthenticated],
    },
    ...routesAuthenticated,
    // 404
    {
        path: '*',
        redirect: { name: ROUTE.START },
    },
]

router.addRoutes(routes)
router.beforeEach(navigationGuard)
router.afterEach(setDocumentTitle)

export { router }

import decodeJWT from 'jwt-decode'
import { PERMISSION } from '@/enums'
import { IUser, IUserAccount } from '@/types'
import { getPermissionsFromTokenPayload } from '../utils'

interface ITokenData {
    exp: number
    permissions: number[]
}

export class UserAccount implements IUserAccount {
    private tokenDataCached?: ITokenData
    public readonly token: string
    public readonly id: number
    public readonly firstName: string
    public readonly lastName: string
    public readonly email: string
    public readonly userRole: string
    public readonly userCompanyId: number
    public readonly urlAvatar: string

    constructor(token: string, user: IUser) {
        this.token = token
        this.id = user.id
        this.firstName = user.firstName
        this.lastName = user.lastName
        this.email = user.email
        this.userRole = user.userRole
        this.userCompanyId = user.userCompanyId
        this.urlAvatar = user.urlAvatar
    }

    get permissions(): PERMISSION[] {
        return getPermissionsFromTokenPayload(this.tokenData.permissions /* FIXME */ || [])
    }

    get tokenExpirationTimestampMs(): number {
        return this.tokenData.exp * 1e3
    }

    private get tokenData(): ITokenData {
        if (!this.tokenDataCached) this.tokenDataCached = decodeJWT<ITokenData>(this.token)

        return this.tokenDataCached
    }
}

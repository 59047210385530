import { API } from '@/api'
import { IJobReferralRejectionPayload } from '@/types'

export async function rejectRefferal(payload: IJobReferralRejectionPayload): Promise<void> {
    const { referralHash, rejectionDescription, rejectionReason } = payload

    await API.post(`referral/${referralHash}/reject`, {
        rejectionDescription,
        rejectionReasonId: rejectionReason.id,
    })
}

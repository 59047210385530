import { API } from '@/api'
import { IJobRecruitmentAvailability } from '@/types'

export async function fetchJobRecruitmentAvailabilities(): Promise<
    IJobRecruitmentAvailability[]
> {
    return [
        {
            id: 'not_looking',
            name: 'Not looking',
        },
        {
            id: 'exceptional',
            name: 'Exceptional offer',
        },
        {
            id: 'open',
            name: 'Open to offers',
        },
        {
            id: 'looking',
            name: 'Actively looking',
        },
        {
            id: 'now',
            name: 'Available now',
        },
    ]
    // TODO
    const { data } = await API.get<IJobRecruitmentAvailability[]>(
        'jobs/recruitment-availabilities'
    )

    return data
}

import { API } from '@/api'
import { IAttachment, IUuid, IRawAttachment } from '@/types'
import { fetchJobApplicationAttachmentFileLink } from './fetchJobApplicationAttachmentFileLink'

export async function fetchJobApplicationAttachments(
    jobId: number,
    jobApplicationId: number
): Promise<IAttachment[]> {
    const { data: rawAttachments } = await API.get<IRawAttachment[]>(
        `jobs/${jobId}/applications/${jobApplicationId}/attachments`
    )

    return rawAttachments.map((rawAttachment) =>
        getAttachment(jobId, jobApplicationId, rawAttachment)
    )
}

function getAttachment(
    jobId: number,
    jobApplicationId: number,
    rawAttachment: IRawAttachment
): IAttachment {
    return {
        ...rawAttachment,
        getFileLink: () =>
            fetchJobApplicationAttachmentFileLink(jobId, jobApplicationId, rawAttachment.name),
    }
}

import { API } from '@/api'
import { API_LIST_REQUEST_LIMIT_AUTOCOMPLETE } from '@/enums'
import { IListRequestParams, IListRequestResult, ICompany } from '@/types'

export async function fetchCompanies({
    offset = 0,
    limit = API_LIST_REQUEST_LIMIT_AUTOCOMPLETE,
    searchText = '',
}: IListRequestParams): Promise<IListRequestResult<ICompany>> {
    const params = { offset, limit, searchText }
    const { data } = await API.get<ICompany[]>('companies', { params })

    return {
        items: data,
        hasMoreItems: data.length === limit,
    }
}

import {
    REPOSITORY_JOB_NOTICE_PERIODS,
    REPOSITORY_JOB_RECRUITMENT_AVAILABILITY,
} from '@/repositiories/job'
import { IUuid, IJobNoticePeriod, IJobRecruitmentAvailability, IUserDetails } from '@/types'
import { IRawUserDetails } from '../types'

export class ConverterUserDetails {
    static async fromRaw(rawDetails: IRawUserDetails): Promise<IUserDetails> {
        const {
            id,
            email,
            first_name: firstName,
            last_name: lastName,
            user_role: userRole,
            user_image: urlAvatar,
            notice_period: noticePeriodId,
            company_assigned: userCompanyId,
            job_status: recruitmentAvailabilityId,
            stackoverflow_link: urlStackOverflow,
            github_link: urlRepositories,
            remote: remotePercentage,
        } = rawDetails
        const [noticePeriod, availability] = await Promise.all([
            this.fetchNoticePeriod((parseInt(noticePeriodId) as unknown) as string),
            this.fetchJobRecruitmentAvailability(recruitmentAvailabilityId),
        ])

        return {
            id,
            email,
            firstName,
            lastName,
            userRole,
            userCompanyId,
            urlAvatar,
            recruitment: {
                noticePeriod,
                availability,
                remotePercentage,
            },
            social: {
                urlStackOverflow,
                urlRepositories,
            },
        }
    }

    static toPartialRaw(details: Partial<IUserDetails>): Partial<IRawUserDetails> {
        const {
            firstName: first_name,
            lastName: last_name,
            userRole: user_role,
            urlAvatar: user_image,
            recruitment,
            social,
        } = details

        return {
            ...(first_name != null && { first_name }),
            ...(last_name != null && { last_name }),
            ...(user_image != null && { user_image }),
            ...(user_role != null && { user_role }),
            // @ts-ignore — this is really fucked up
            ...(recruitment?.noticePeriod != null && {
                noticePeriod: recruitment.noticePeriod?.id!,
            }),
            ...(recruitment?.availability != null && {
                job_status: recruitment.availability.id,
            }),
            ...(recruitment?.remotePercentage != null && {
                remote: recruitment.remotePercentage,
            }),
            ...(social?.urlStackOverflow != null && {
                stackoverflow_link: social.urlStackOverflow,
            }),
            ...(social?.urlRepositories != null && { github_link: social.urlRepositories }),
        }
    }

    private static async fetchNoticePeriod(
        noticePeriodId: IUuid
    ): Promise<IJobNoticePeriod | undefined> {
        try {
            return await REPOSITORY_JOB_NOTICE_PERIODS.fetchNoticePeriod(noticePeriodId)
        } catch (e) {
            return
        }
    }

    private static async fetchJobRecruitmentAvailability(
        recruitmentAvailabilityId: IUuid
    ): Promise<IJobRecruitmentAvailability> {
        try {
            return await REPOSITORY_JOB_RECRUITMENT_AVAILABILITY.fetchJobRecruitmentAvailability(
                recruitmentAvailabilityId
            )
        } catch (e) {
            return (
                await REPOSITORY_JOB_RECRUITMENT_AVAILABILITY.fetchJobRecruitmentAvailabilities()
            )[0]
        }
    }
}

import { API } from '@/api'
import { IUserDetails } from '@/types'
import { ConverterUserDetails } from '../converters'
import { fetchUserDetails } from './fetchUserDetails'

export async function updateUserDetails(payload: Partial<IUserDetails>): Promise<IUserDetails> {
    await API.post('profile/save', ConverterUserDetails.toPartialRaw(payload))

    return await fetchUserDetails()
}

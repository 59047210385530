import { API } from '@/api'
import { IUserSkillProfile, IUserSkillProfileUpdateConfig } from '@/types'

export async function updateUserSkill(
    payload: IUserSkillProfileUpdateConfig
): Promise<IUserSkillProfile> {
    const { uuid, ...config } = payload
    const { data } = await API.patch<IUserSkillProfile>(`users/me/skills/${uuid}`, config)

    return data
}

import { ABILITY_ACTION, ABILITY_SUBJECT, ROUTE } from '@/enums'
import { IRouteConfig } from '@/router/types'

export const routesUnauthenticated: IRouteConfig[] = [
    {
        path: '',
        name: ROUTE.START,
        component: () =>
            import(/* webpackChunkName: "home" */ '@/pages/TheFrontFrame/views/Home/Home.vue'),
        meta: {
            title: 'Start',
            abilityAction: ABILITY_ACTION.READ,
            abilitySubject: ABILITY_SUBJECT.UNAUTHENTICATED,
        },
    },
    {
        path: '/for-networker',
        name: ROUTE.START_FOR_NETWORKER,
        component: () =>
            import(
                /* webpackChunkName: "landing-page-for-networker" */ '@/pages/TheFrontFrame/views/LandingForNetworker/LandingForNetworker.vue'
            ),
        meta: {
            title: 'How valuable is your network?',
            abilityAction: ABILITY_ACTION.READ,
            abilitySubject: ABILITY_SUBJECT.UNAUTHENTICATED,
        },
    },
    {
        path: '/for-candidate',
        name: ROUTE.START_FOR_CANDIDATE,
        component: () =>
            import(
                /* webpackChunkName: "landing-page-for-candidate" */ '@/pages/TheFrontFrame/views/LandingForCandidate/LandingForCandidate.vue'
            ),
        meta: {
            title: 'Recruitment Anti-Spam system',
            abilityAction: ABILITY_ACTION.READ,
            abilitySubject: ABILITY_SUBJECT.UNAUTHENTICATED,
        },
    },
    {
        path: '/for-recruiter',
        name: ROUTE.START_FOR_RECRUITER,
        component: () =>
            import(
                /* webpackChunkName: "landing-page-for-recruiter" */ '@/pages/TheFrontFrame/views/LandingForRecruiter/LandingForRecruiter.vue'
            ),
        meta: {
            title: 'How not to suck in recruitment',
            abilityAction: ABILITY_ACTION.READ,
            abilitySubject: ABILITY_SUBJECT.UNAUTHENTICATED,
        },
    },
    {
        path: '/auth/signin',
        name: ROUTE.SIGN_IN,
        component: () =>
            import(
                /* webpackChunkName: "signin" */ '@/pages/TheFrontFrame/views/SignIn/SignIn.vue'
            ),
        props: true,
        meta: {
            title: 'Sign in',
            abilityAction: ABILITY_ACTION.READ,
            abilitySubject: ABILITY_SUBJECT.UNAUTHENTICATED,
        },
    },
    {
        path: '/auth/confirm/:code',
        name: ROUTE.SIGN_UP_CONFIRM,
        component: () =>
            import(
                /* webpackChunkName: "signup-confirmation" */ '@/pages/TheFrontFrame/views/SignUpConfirmation/SignUpConfirmation.vue'
            ),
        meta: {
            title: 'Sign up confirmation',
            abilityAction: ABILITY_ACTION.READ,
            abilitySubject: ABILITY_SUBJECT.UNAUTHENTICATED,
            forceSignOut: true,
        },
    },
    {
        path: '/auth/recovery/:token',
        name: ROUTE.PASSWORD_RECOVERY,
        component: () =>
            import(
                /* webpackChunkName: "password-recovery" */ '@/pages/TheFrontFrame/views/PasswordRecovery/PasswordRecovery.vue'
            ),
        meta: {
            title: 'Password recovery',
            abilityAction: ABILITY_ACTION.READ,
            abilitySubject: ABILITY_SUBJECT.UNAUTHENTICATED,
            forceSignOut: true,
        },
    },
    {
        path: '/confirmSubscription',
        name: ROUTE.SUBSCRIPTION_CONFIRM,
        component: () =>
            import(
                /* webpackChunkName: "confirm-subscription" */ '@/pages/TheFrontFrame/views/ConfirmSubscription/ConfirmSubscription.vue'
            ),
        meta: {
            title: 'Confirm your subscription',
            abilityAction: ABILITY_ACTION.READ,
            abilitySubject: ABILITY_SUBJECT.UNAUTHENTICATED,
            forceSignOut: true,
        },
    },
]

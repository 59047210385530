import { store } from './root'
import { STORE_MODULE } from '@/enums'

import referrals from './modules/referrals'
import responseRates from './modules/responseRates'
import subscriptions from './modules/subscriptions'
import {
    auth,
    onboarding,
    signUp,
    jobSearch,
    dashboardJobApplications,
    dashboardUserProfile,
    dashboardReferredPeople,
    dashboardUserSkills,
    dashboardUserNewJobSpecifications,
    dashboardUserNewJobSpecificationDetails,
    dashboardCompanyAddresses,
    dashboardCompanies,
    dashbaordJobRecruitmentProcess,
    dashboardCandidates,
} from './modules'
store.registerModule('referrals', referrals)
store.registerModule('responseRates', responseRates)
store.registerModule('subscriptions', subscriptions)
store.registerModule(STORE_MODULE.AUTH, auth)
store.registerModule(STORE_MODULE.ONBOARDING, onboarding)
store.registerModule(STORE_MODULE.SIGN_UP, signUp)
store.registerModule(STORE_MODULE.JOB_SEARCH, jobSearch)
store.registerModule(STORE_MODULE.DASHBOARD_USER_PROFILE, dashboardUserProfile)
store.registerModule(STORE_MODULE.DASHBOARD_JOB_APPLICATIONS, dashboardJobApplications)
store.registerModule(STORE_MODULE.DASHBOARD_USER_REFERRED_PEOPLE, dashboardReferredPeople)
store.registerModule(STORE_MODULE.DASHBOARD_USER_SKILLS, dashboardUserSkills)
store.registerModule(
    STORE_MODULE.DASHBOARD_USER_NEW_JOB_SPECIFICATIONS,
    dashboardUserNewJobSpecifications
)
store.registerModule(
    STORE_MODULE.DASHBOARD_USER_NEW_JOB_SPECIFICATION_DETAILS,
    dashboardUserNewJobSpecificationDetails
)
store.registerModule(STORE_MODULE.DASHBOARD_COMPANY_ADDRESSES, dashboardCompanyAddresses)
store.registerModule(STORE_MODULE.DASHBOARD_COMPANIES, dashboardCompanies)
store.registerModule(STORE_MODULE.DASHBOARD_PROCESS, dashbaordJobRecruitmentProcess)
store.registerModule(STORE_MODULE.DASHBOARD_CANDIDATES, dashboardCandidates)

export { store }

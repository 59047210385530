import { ABILITY_ACTION, ABILITY_SUBJECT, ROUTE } from '@/enums'
import { ability } from '@/ability'
import { NavigationGuard, RawLocation, Route } from 'vue-router'
import { signOut, signInIfNotSignedAndValidTokenStored, canNavigateTo } from './utils'

export const navigationGuard: NavigationGuard = async (to, from, next): Promise<void> => {
    if (isForcedToSignOut(to)) signOut()

    try {
        await signInIfNotSignedAndValidTokenStored()
    } catch (error) {
        return isTargetRouteForAuthenticated(to) ? next(getSignInRoute(to)) : next()
    }

    if (!isSignedIn() && isTargetRouteForAuthenticated(to)) {
        return next(getSignInRoute(to))
    }

    if (canNavigateTo(to)) return next()

    return next(from.name ? false : { name: ROUTE.DASHBOARD })
}

function isForcedToSignOut(to: Route): boolean {
    return !!to.meta.forceSignOut
}

function isTargetRouteForAuthenticated(to: Route): boolean {
    return ![ABILITY_SUBJECT.UNAUTHENTICATED, ABILITY_SUBJECT.PUBLIC].includes(
        to.meta.abilitySubject
    )
}

function isSignedIn(): boolean {
    return ability.can(ABILITY_ACTION.READ, ABILITY_SUBJECT.AUTHENTICATED)
}

function getSignInRoute(to: Route): RawLocation {
    return {
        name: ROUTE.SIGN_IN,
        params: { previousRoutePath: to.path },
    }
}

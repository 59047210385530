import { createApiCache, createApiCacheStaticResourceGetter } from '@/api'
import { IJobApplicationState } from '@/types'
import { fetchJobApplicationStates } from './fetchJobApplicationStates'

const cache = createApiCache<number, IJobApplicationState>()
const { getCachedItem } = createApiCacheStaticResourceGetter<number, IJobApplicationState>(
    cache,
    'id',
    fetchJobApplicationStates
)

export async function fetchJobApplicationState(stateId: number): Promise<IJobApplicationState> {
    const state = await getCachedItem(stateId)

    if (!state) {
        throw new Error(`Job application state with id: "${stateId}" does not exist`)
    }

    return state
}

import { AxiosError, AxiosResponse, AxiosRequestConfig } from 'axios'
import { IApiErrorResponse, IApiErrorResponseData } from '../types'

export class ApiError {
    public name: string
    public message: string
    public config: AxiosRequestConfig
    public code?: string
    public request?: any
    public response: IApiErrorResponse
    public isAxiosError: boolean

    constructor(error: AxiosError) {
        this.name = error.name || 'ApiError'
        this.message = error.message || 'API error'
        this.config = error.config || {}
        this.code = error.code
        this.request = error.request
        this.response = this.getDefaultResponse(error.response)
        this.isAxiosError = error.isAxiosError || false
    }

    private getDefaultResponse(
        response?: AxiosResponse<IApiErrorResponseData>
    ): IApiErrorResponse {
        return {
            status: 0,
            statusText: '',
            headers: undefined,
            config: {},
            ...response,
            data: {
                error: this.name,
                message: this.message,
                ...response?.data,
            },
        }
    }
}

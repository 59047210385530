import { API } from '@/api'
import { INetworkWorthCalculation } from '@/types/networkWorthCalculation'

export async function fetchCalculation(
    networkSize: number,
    invitationUuid?: string
): Promise<INetworkWorthCalculation> {
    const params = { networkSize, invitationUuid }
    const { data } = await API.get<INetworkWorthCalculation>('calculate-network-worth', {
        params,
    })

    return data
}

import { API } from '@/api'
import { ICompany, IUuid } from '@/types'

export async function createBlacklistedCompany(
    specificationUuid: IUuid,
    company: ICompany
): Promise<ICompany> {
    const { data } = await API.post<ICompany>(
        `users/me/new-job-specifications/${specificationUuid}/blacklisted-companies`,
        company
    )

    return data
}

import { API } from '@/api'
import { IUser } from '@/types'
import { IRawUser } from '../types'

export async function fetchUserFromToken(token: string): Promise<IUser> {
    const config = { headers: { Authorization: `Bearer ${token}` } }
    const {
        data: {
            id,
            first_name: firstName,
            last_name: lastName,
            email,
            user_role: userRole,
            company_assigned: userCompanyId,
            user_image: urlAvatar,
        },
    } = await API.get<IRawUser>('profile', config)

    return {
        id,
        firstName,
        lastName,
        email,
        userRole,
        userCompanyId,
        urlAvatar,
    }
}

import { ActionTree, MutationTree } from 'vuex'
import { ICredentials, IStateAuth, IStateRoot, IUserAccount } from '@/types'
import {
    MUTATION_AUTH,
    ACTION_AUTH,
    TOKEN_LOCAL_STORAGE_KEY,
    STORE_MODULE,
    ACTION_ONBOARDING,
    MUTATION_ONBOARDING,
} from '@/enums'
import { REPOSITORY_AUTH } from '@/repositiories'
import { defineAbilitiesForUserAccount } from '@/ability'

const state = (): IStateAuth => ({
    userAccount: undefined,
    refreshPromise: undefined,
})

export const mutations: MutationTree<IStateAuth> = {
    [MUTATION_AUTH.SET_USER_ACCOUNT](state, userAccount?: IUserAccount) {
        state.userAccount = userAccount
    },

    [MUTATION_AUTH.SET_REFRESH_PROMISE](state, refreshPromise?: Promise<void>) {
        state.refreshPromise = refreshPromise
    },
}

export const actions: ActionTree<IStateAuth, IStateRoot> = {
    async [ACTION_AUTH.SIGN_IN_FROM_CREDENTIALS](
        { dispatch },
        credentials: ICredentials
    ): Promise<void> {
        try {
            const userAccount = await REPOSITORY_AUTH.fetchUserAccountFromCredentials(
                credentials
            )

            await dispatch(ACTION_AUTH.SIGN_IN, userAccount)
        } catch (error) {
            return Promise.reject(error)
        }
    },

    async [ACTION_AUTH.SIGN_IN_FROM_TOKEN]({ dispatch }, token: string): Promise<void> {
        try {
            const userAccount = await REPOSITORY_AUTH.fetchUserAccountFromToken(token)

            await dispatch(ACTION_AUTH.SIGN_IN, userAccount)
        } catch (error) {
            return Promise.reject(error)
        }
    },

    async [ACTION_AUTH.REFRESH]({ state, commit, dispatch }): Promise<void> {
        const token = state.userAccount?.token

        if (!token) return

        try {
            commit(
                MUTATION_AUTH.SET_REFRESH_PROMISE,
                dispatch(ACTION_AUTH.SIGN_IN_FROM_TOKEN, token)
            )

            await state.refreshPromise
        } catch (error) {
            return Promise.reject(error)
        }

        commit(MUTATION_AUTH.SET_REFRESH_PROMISE, undefined)
    },

    async [ACTION_AUTH.SIGN_IN](
        { commit, dispatch },
        userAccount: IUserAccount
    ): Promise<void> {
        commit(MUTATION_AUTH.SET_USER_ACCOUNT, userAccount)
        localStorage.setItem(TOKEN_LOCAL_STORAGE_KEY, userAccount.token)
        defineAbilitiesForUserAccount(userAccount.permissions)

        await dispatch(
            `${STORE_MODULE.ONBOARDING}/${ACTION_ONBOARDING.FETCH_AND_SET_ONBOARDING_STATE}`,
            null,
            { root: true }
        )
    },

    async [ACTION_AUTH.SIGN_OUT]({ state, commit }): Promise<void> {
        if (state.refreshPromise) await state.refreshPromise

        commit(MUTATION_AUTH.SET_USER_ACCOUNT, undefined)
        localStorage.removeItem(TOKEN_LOCAL_STORAGE_KEY)
        defineAbilitiesForUserAccount()

        commit(
            `${STORE_MODULE.ONBOARDING}/${MUTATION_ONBOARDING.RESET_ONBOARDING_STATE}`,
            null,
            { root: true }
        )
    },
}

export const auth = {
    namespaced: true,
    state,
    mutations,
    actions,
}

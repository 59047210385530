export enum MUTATION_AUTH {
    SET_USER_ACCOUNT = 'SetUserAccount',
    SET_REFRESH_PROMISE = 'SetRefreshPromise',
}

export enum MUTATION_SIGN_UP {
    SET_INVITATION_UUID = 'SetInvitationUuid',
    SET_INVITATION = 'SetInvitation',
}

export enum MUTATION_ONBOARDING {
    SET_ONBOARDING_STATE = 'SetOnboardingState',
    RESET_ONBOARDING_STATE = 'ResetOnboardingState',
}

export enum MUTATION_JOB_SEARCH {
    SET_FILTERS = 'SetFilters',
    SET_JOBS = 'SetJobs',
    ADD_JOBS = 'AddJobs',
    SET_HAS_FETCHED_ALL_JOBS = 'SetHasFetchedAllJobs',
    SET_ARE_FILTERS_EXPANDED = 'SetAreFiltersExpanded',
}

export enum MUTATION_DASHBOARD_JOB_APPLICATIONS {
    SET_APPLICATIONS = 'SetApplications',
}

export enum MUTATION_DASHBOARD_USER_PROFILE {
    SET_USER_DETAILS = 'SetUserDetails',
}

export enum MUTATION_DASHBOARD_USER_SKILLS {
    SET_USER_SKILLS = 'SetUserSkills',
    UPDATE_USER_SKILL = 'UpdateUserSkill',
}

export enum MUTATION_USER_NEW_JOB_SPECIFICATIONS {
    SET_SPECIFICATIONS = 'SetSpecifications',
    REMOVE_SPECIFICATION = 'RemoveSpecification',
}

export enum MUTATION_USER_NEW_JOB_SPECIFICATION_DETAILS {
    SET_SPECIFICATION = 'SetSpecification',
    UPDATE_SPECIFICATION = 'UpdateSpecification',
}

export enum MUTATION_DASHBOARD_REFERRED_PEOPLE {
    SET_REFERRED_PEOPLE = 'SetReferredPeople',
}

export enum MUTATION_DASHBOARD_COMPANIES {
    SET_COMPANIES = 'SetCompanies',
}

export enum MUTATION_DASHBOARD_COMPANY_ADDRESSES {
    SET_COMPANY_ADDRESSES = 'SetCompanyAddresses',
    ADD_COMPANY_ADDRESS = 'AddCompanyAddress',
    CHANGE_HEADQUARTERS_COMPANY_ADDRESS = 'ChangeHeadquartersCompanyAddresses',
    DELETE_COMPANY_ADDRESS = 'DeleteCompanyAddress',
}

export enum MUTATION_DASHBOARD_PROCESS {
    SET_PROCESSES = 'SetProcesses',
    CLONE_PROCESS = 'CloneProcess',
    ADD_PROCESSES = 'AddProcess',
    UPDATE_PROCESS = 'UpdateProcess',
    DELETE_PROCESS = 'DeleteProcess',
    SET_PROCESS = 'SET_PROCESS',
    ADD_STAGE = 'ADD_STAGE',
    DELETE_STAGE = 'DELETE_STAGE',
}

export enum MUTATION_DASHBOARD_CANDIDATES {
    SET_CANDIDATES = 'SetCandidates',
}

import Vue from 'vue'

const state = {
    responseRates: [],
}

export const getters = {
    getResponseRates(...[state, , , rootGetters]) {
        return state.responseRates
    },
}

export const actions = {
    async fetchResponseRates({ commit, rootState }, companyId) {
        const url = '/admin/getResponseRate/byCompany/' + companyId
        const response = await rootState.backend.get(url)

        commit('setResponseRates', { responseRates: response.data })
    },
}

export const mutations = {
    setResponseRates(state, payload) {
        state.responseRates = payload
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}

import { MUTATION_DASHBOARD_COMPANIES, ACTION_DASHBOARD_COMPANIES } from '@/enums'
import { REPOSITORY_COMPANIES } from '@/repositiories'
import { IStateDashboardCompanies, IStateRoot, ICompanyDetails } from '@/types/'
import { ActionTree, MutationTree } from 'vuex'

const state = (): IStateDashboardCompanies => ({
    companies: undefined,
})

export const mutations: MutationTree<IStateDashboardCompanies> = {
    [MUTATION_DASHBOARD_COMPANIES.SET_COMPANIES](state, companies: ICompanyDetails[]) {
        state.companies = companies
    },
}

export const actions: ActionTree<IStateDashboardCompanies, IStateRoot> = {
    async [ACTION_DASHBOARD_COMPANIES.FETCH_AND_SET_COMPANIES]({ commit }): Promise<void> {
        try {
            const companies = await REPOSITORY_COMPANIES.fetchCompaniesWithDetails()

            commit(MUTATION_DASHBOARD_COMPANIES.SET_COMPANIES, companies)
        } catch (error) {
            alert(error.data.message)
        }
    },
}

export const dashboardCompanies = {
    namespaced: true,
    state,
    mutations,
    actions,
}

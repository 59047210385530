import { IUserSkill } from '@/types'
import { fetchUserSkills } from './fetchUserSkills'

export async function fetchCoreUserSkills(
    limit: number,
    userId?: number
): Promise<IUserSkill[]> {
    const skills = await fetchUserSkills(userId)
    return skills
        .sort((a: IUserSkill, b: IUserSkill) => (a.score || 0) - (b.score || 0))
        .slice(0, limit)
}

import { API } from '@/api'
import { IUserDetails } from '@/types'
import { ConverterUserDetails } from '../converters'
import { IRawUserDetails } from '../types'

export async function fetchUserDetails(): Promise<IUserDetails> {
    const { data } = await API.get<IRawUserDetails>('profile')

    return await ConverterUserDetails.fromRaw(data)
}

import { API } from '@/api'
import { IJobReferral } from '@/types'

export async function fetchReferral(referralHash: string): Promise<IJobReferral> {
    const { data } = await API.get<Omit<IJobReferral, 'referralHash'>>(
        `referral/${referralHash}`
    )

    return { ...data, referralHash }
}

import { API } from '@/api'
import { IUserNewJobSpecificationConfig, IUserNewJobSpecificationDetails, IUuid } from '@/types'
import { ConverterNewJobSpecificationDetails } from '../converters'
import { IRawUserNewJobSpecificationDetails } from '../types'

export async function updateSpecification(
    specificationUuid: IUuid,
    specificationConfig: Partial<IUserNewJobSpecificationConfig>
): Promise<IUserNewJobSpecificationDetails> {
    const payload = ConverterNewJobSpecificationDetails.toPartialRaw(specificationConfig)
    const { data } = await API.patch<IRawUserNewJobSpecificationDetails>(
        `users/me/new-job-specifications/${specificationUuid}`,
        payload
    )

    return await ConverterNewJobSpecificationDetails.fromRaw(data)
}

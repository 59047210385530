import { API } from '@/api'
import { IUuid } from '@/types'

export async function deletePreferredCompany(
    specificationUuid: IUuid,
    companyId: number
): Promise<void> {
    await API.delete(
        `users/me/new-job-specifications/${specificationUuid}/preferred-companies/${companyId}`
    )
}

import {
    ACTION_DASHBOARD_COMPANY_ADDRESSES,
    MUTATION_DASHBOARD_COMPANY_ADDRESSES,
} from '@/enums'
import { REPOSITORY_COMPANIES } from '@/repositiories'
import { IStateDashboardCompanyAddresses, IStateRoot, ICompanyAddress } from '@/types'
import { ActionTree, MutationTree } from 'vuex'
import Vue from 'vue'
const state = (): IStateDashboardCompanyAddresses => ({
    companyAddresses: undefined,
})

export const mutations: MutationTree<IStateDashboardCompanyAddresses> = {
    [MUTATION_DASHBOARD_COMPANY_ADDRESSES.SET_COMPANY_ADDRESSES](
        state,
        companyAddresses: ICompanyAddress[]
    ) {
        state.companyAddresses = companyAddresses
    },

    [MUTATION_DASHBOARD_COMPANY_ADDRESSES.ADD_COMPANY_ADDRESS](
        state,
        companyAddress: ICompanyAddress
    ) {
        state.companyAddresses?.push(companyAddress)
    },

    [MUTATION_DASHBOARD_COMPANY_ADDRESSES.CHANGE_HEADQUARTERS_COMPANY_ADDRESS](
        state,
        companyAddresses: ICompanyAddress[]
    ) {
        if (!companyAddresses) return

        companyAddresses.forEach((companyAddress) => {
            if (state.companyAddresses) {
                Vue.set(
                    state.companyAddresses,
                    state.companyAddresses.findIndex((x) => x.id === companyAddress.id),
                    companyAddress
                )
            }
        })
    },

    [MUTATION_DASHBOARD_COMPANY_ADDRESSES.DELETE_COMPANY_ADDRESS](state, addressId: number) {
        state.companyAddresses = state.companyAddresses?.filter((obj) => obj.id !== addressId)
    },
}

export const actions: ActionTree<IStateDashboardCompanyAddresses, IStateRoot> = {
    async [ACTION_DASHBOARD_COMPANY_ADDRESSES.FETCH_AND_SET_COMPANY_ADDRESSES](
        { commit },
        companyId: number
    ): Promise<void> {
        try {
            const companyAddresses = await REPOSITORY_COMPANIES.getAddresses(companyId)

            commit(MUTATION_DASHBOARD_COMPANY_ADDRESSES.SET_COMPANY_ADDRESSES, companyAddresses)
        } catch (error) {
            alert(error.data.message)
        }
    },

    async [ACTION_DASHBOARD_COMPANY_ADDRESSES.ADD_COMPANY_ADDRESS](
        { commit },
        address: ICompanyAddress
    ): Promise<void> {
        try {
            const companyAddresses = await REPOSITORY_COMPANIES.addAddress(address)

            commit(MUTATION_DASHBOARD_COMPANY_ADDRESSES.ADD_COMPANY_ADDRESS, companyAddresses)
        } catch (error) {
            alert(error.data.message)
        }
    },

    async [ACTION_DASHBOARD_COMPANY_ADDRESSES.CHANGE_HEADQUARTERS_COMPANY_ADDRESSES](
        { commit },
        addresses: ICompanyAddress[]
    ): Promise<void> {
        try {
            await REPOSITORY_COMPANIES.changeHeadquarters(addresses)

            commit(
                MUTATION_DASHBOARD_COMPANY_ADDRESSES.CHANGE_HEADQUARTERS_COMPANY_ADDRESS,
                addresses
            )
        } catch (error) {
            alert(error.data.message)
        }
    },

    async [ACTION_DASHBOARD_COMPANY_ADDRESSES.DELETE_COMPANY_ADDRESS](
        { commit },
        addressId: number
    ): Promise<void> {
        try {
            await REPOSITORY_COMPANIES.deleteAddress(addressId)

            commit(MUTATION_DASHBOARD_COMPANY_ADDRESSES.DELETE_COMPANY_ADDRESS, addressId)
        } catch (error) {
            alert(error.data.message)
        }
    },
}

export const dashboardCompanyAddresses = {
    namespaced: true,
    state,
    mutations,
    actions,
}

import { API } from '@/api'
import { IJobApplicationActionCallPayload } from '@/types'

export async function callJobApplicationAction({
    jobId,
    jobApplicationId,
    jobApplicationActionId,
}: IJobApplicationActionCallPayload): Promise<void> {
    await API.post(
        `jobs/${jobId}/applications/${jobApplicationId}/actions/${jobApplicationActionId}`
    )
}

import { API } from '@/api'
import { IUserAccount } from '@/types'
import { UserAccount } from '../models/UserAccount'
import { fetchUserFromToken } from './fetchUserFromToken'

export async function fetchUserAccountFromToken(token: string): Promise<IUserAccount> {
    const config = { headers: { Authorization: `Bearer ${token}` } }
    const { data: newToken } = await API.post<string>('auth/refresh-token', null, config)
    const user = await fetchUserFromToken(newToken)

    return new UserAccount(newToken, user)
}

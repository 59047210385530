import { API } from '@/api'
import { IUserSkillProfile, IUserSkillProfileConfig } from '@/types'

export async function createUserSkill(
    payload: IUserSkillProfileConfig
): Promise<IUserSkillProfile> {
    const { data } = await API.post<IUserSkillProfile>(`users/me/skills`, payload)

    return data
}

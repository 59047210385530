import { API } from '@/api'
import { IUserNewJobSpecificationDetails, IUuid } from '@/types'
import { ConverterNewJobSpecificationDetails } from '../converters'
import { IRawUserNewJobSpecificationDetails } from '../types'

export async function fetchSpecification(
    specificationUuid: IUuid
): Promise<IUserNewJobSpecificationDetails> {
    const { data } = await API.get<IRawUserNewJobSpecificationDetails>(
        `users/me/new-job-specifications/${specificationUuid}`
    )

    return await ConverterNewJobSpecificationDetails.fromRaw(data)
}

export enum STORE_MODULE {
    AUTH = 'Auth',
    ONBOARDING = 'Onboarding',
    SIGN_UP = 'SignUp',
    JOB_SEARCH = 'JobSearch',
    DASHBOARD_JOB_APPLICATIONS = 'DashboardJobApplications',
    DASHBOARD_USER_PROFILE = 'DashboardUserProfile',
    DASHBOARD_USER_SKILLS = 'DashboardUserSkills',
    DASHBOARD_USER_NEW_JOB_SPECIFICATIONS = 'DashboardUserNewJobSpecifications',
    DASHBOARD_USER_NEW_JOB_SPECIFICATION_DETAILS = 'DashboardUserNewJobSpecificationDetails',
    DASHBOARD_USER_REFERRED_PEOPLE = 'DashboardUserReferredPeople',
    DASHBOARD_COMPANY_ADDRESSES = 'DashboardCompanyAddresses',
    DASHBOARD_COMPANIES = 'DashboardCompanies',
    DASHBOARD_PROCESS = 'DashboardProcess',
    DASHBOARD_CANDIDATES = 'DashboardCandidates',
}

import { API } from '@/api'
import { IFile } from '@/types'
import { getFormDataFromFile } from '@/utils'

export async function uploadUserAvatar(file: IFile): Promise<string> {
    const formData = getFormDataFromFile(file, 'image')
    const { data } = await API.post('profile/upload', formData)

    return data.url
}

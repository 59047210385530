<template>
    <RouterView />
</template>

<script>
export default {
    name: 'App',

    mounted() {
        if (this.$route.query.reflink) this.updateReflinkHashes()
        if (this.$route.query.invite) this.updateInviteHashes()
    },

    methods: {
        updateHashes(hash) {
            const array = [...JSON.parse(localStorage.hashes || '[]'), hash]
            const cleanedArray = JSON.stringify([...new Set([...array])])
            localStorage.hashes = cleanedArray
        },

        updateReflinkHashes() {
            this.$store.state.backend
                .post('/tracking/reflink', {
                    user: this.$route.query.reflink,
                    medium: 'reflink',
                })
                .then((res) => this.updateHashes(res.data.hash))
                .catch((err) => alert(err))
        },

        updateInviteHashes() {
            this.updateHashes(this.$route.query.invite)
        },
    },
}
</script>

<style lang="sass">
@import '@/styles/overrides'

html, body
    min-height: 100%

body
  font-family: 'Source Sans Pro', sans-serif

nav
  background-color: white
  border-bottom: 1px solid rgba(0,0,0,0.1)
  margin: 0 auto
  font-weight: 400

h1,h2,a
  color: $primaryColor

.card
  box-shadow: 0 2px 6px 0 hsla(0,0%,0%,0.1)
  border: 0

.primary
    background-color: $primaryColor

::placeholder
    color: rgba(black, .3) !important
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style src="vue-slider-component/theme/antd.css"></style>

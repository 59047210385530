import _Vue from 'vue'
import VueRouter from 'vue-router'

declare module 'vue/types/vue' {
    interface Vue {
        $isRouterBack(): Promise<boolean>
    }
}

const nextEventLoop = () =>
    new Promise((resolve) => {
        setTimeout(resolve, 0)
    })

export const VueIsRouterBack = {
    install(Vue: typeof _Vue, router: VueRouter) {
        const vm = new Vue({ router })
        let poppedToPath: string | undefined
        let isBack = false

        addEventListener('popstate', (event) => {
            poppedToPath = location.pathname
        })

        vm.$watch('$route.path', async (path) => {
            await nextEventLoop()

            isBack = path === poppedToPath
            poppedToPath = undefined
        })

        Vue.prototype.$isRouterBack = async function (): Promise<boolean> {
            await nextEventLoop()

            return isBack
        }
    },
}

import { IJobApplication } from '@/types'
import { IRawJobApplication } from '../types'
import { REPOSITORY_JOB_NOTICE_PERIODS } from '../../jobNoticePeriods'
import { fetchJobApplicationState } from '../actions'
import dayjs from 'dayjs'

export async function getJobApplication(
    rawApplication: IRawJobApplication
): Promise<IJobApplication> {
    const {
        id,
        jobId,
        applicationStateId,
        firstName,
        lastName,
        email,
        referral,
        allSkillsMatched: areAllSkillsMatched,
        partTimeAvailable: isPartTimeAvailable,
        whyImGood: descriptionWhyImGood,
        whatsMissing: descriptionWhatIsMissing,
        noticePeriodId,
        applicationDate,
    } = rawApplication
    const [state, noticePeriod] = await Promise.all([
        fetchJobApplicationState(applicationStateId),
        REPOSITORY_JOB_NOTICE_PERIODS.fetchNoticePeriod(noticePeriodId),
    ])

    return {
        id,
        state,
        jobId,
        firstName,
        lastName,
        email,
        referral,
        areAllSkillsMatched,
        isPartTimeAvailable,
        descriptionWhyImGood,
        descriptionWhatIsMissing,
        noticePeriod,
        timestampCreated: dayjs(applicationDate).valueOf(),
    }
}

import { MUTATION_DASHBOARD_CANDIDATES, ACTION_DASHBOARD_CANDIDATES } from '@/enums'
import { REPOSITORY_CANDIDATE } from '@/repositiories'
import { IStateDashboardCandidates, IStateRoot, ICandidate } from '@/types/'
import { ActionTree, MutationTree } from 'vuex'

const state = (): IStateDashboardCandidates => ({
    candidates: undefined,
})

export const mutations: MutationTree<IStateDashboardCandidates> = {
    [MUTATION_DASHBOARD_CANDIDATES.SET_CANDIDATES](state, candidates: ICandidate[]) {
        state.candidates = candidates
    },
}

export const actions: ActionTree<IStateDashboardCandidates, IStateRoot> = {
    async [ACTION_DASHBOARD_CANDIDATES.FETCH_AND_SET_CANDIDATES]({ commit }): Promise<void> {
        try {
            const companies = await REPOSITORY_CANDIDATE.fetchCandidates()

            commit(MUTATION_DASHBOARD_CANDIDATES.SET_CANDIDATES, companies)
        } catch (error) {
            alert(error.data.message)
        }
    },
}

export const dashboardCandidates = {
    namespaced: true,
    state,
    mutations,
    actions,
}

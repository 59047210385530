import { PERMISSION, ABILITY_ACTION, ABILITY_SUBJECT } from '@/enums'

type IAbilitiesByPermissions = {
    [key in PERMISSION]: [ABILITY_ACTION, ABILITY_SUBJECT]
}

export const ABILITIES_BY_PERMISSIONS: IAbilitiesByPermissions = {
    [PERMISSION.CAN_READ_JOBS]: [ABILITY_ACTION.READ, ABILITY_SUBJECT.JOBS],
    [PERMISSION.CAN_CREATE_JOBS]: [ABILITY_ACTION.CREATE, ABILITY_SUBJECT.JOBS],
    [PERMISSION.CAN_UPDATE_JOBS]: [ABILITY_ACTION.UPDATE, ABILITY_SUBJECT.JOBS],
    [PERMISSION.CAN_DELETE_JOBS]: [ABILITY_ACTION.DELETE, ABILITY_SUBJECT.JOBS],
}

import Vue from 'vue'
import { abilitiesPlugin } from '@casl/vue'
import { ability } from './ability'
import { router } from './router'
import { store } from './store'
import { VueIsRouterBack } from './plugins'
import BootstrapVue from 'bootstrap-vue'
import Meta from 'vue-meta'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import App from './App.vue'

Vue.component('FontAwesomeIcon', FontAwesomeIcon)
Vue.use(abilitiesPlugin, ability)
Vue.use(BootstrapVue)
Vue.use(Meta)
Vue.use(VueIsRouterBack, router)

Vue.config.devtools = true
Vue.config.productionTip = false

new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount('#app')

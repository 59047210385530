import { IFile } from '@/types'

export function getFormDataFromFile(file: IFile, formFileName = 'file'): FormData {
    const data = new FormData()
    const { content, type, name, lastModified } = file
    const blob = new Blob([content], { type })

    data.append(formFileName, new File([blob], name, { lastModified }))

    return data
}

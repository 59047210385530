import { store } from '@/store/root'
import { ACTION_AUTH, STORE_MODULE } from '@/enums'
import { IStateAuth, IUserAccount } from '@/types'

const TOKEN_EXPIERY_MARGIN_MS = 1 /* min */ * 6e4
let isRefreshingToken = false

export async function refreshTokenIfNearlyExpired(): Promise<void> {
    const state = (store.state as unknown) as { [STORE_MODULE.AUTH]: IStateAuth }
    const userAccount = state[STORE_MODULE.AUTH].userAccount

    if (!isRefreshingToken && !!userAccount && isTokenNearlyExpired(userAccount)) {
        isRefreshingToken = true

        await store.dispatch(
            `${STORE_MODULE.AUTH}/${ACTION_AUTH.SIGN_IN_FROM_TOKEN}`,
            userAccount.token
        )

        isRefreshingToken = false
    }
}

function isTokenNearlyExpired(userAccount: IUserAccount): boolean {
    return Date.now() + TOKEN_EXPIERY_MARGIN_MS > userAccount.tokenExpirationTimestampMs
}

import { IUserNewJobSpecificationConfig, IUserNewJobSpecificationDetails } from '@/types'
import { ConverterNewJobSpecification } from '../../converters'
import { IRawUserNewJobSpecificationDetails, IRawUserNewJobSpecificationConfig } from '../types'
import { fetchBlacklistedCompanies } from '../actions/blacklistedCompanies'
import { fetchPreferredCities } from '../actions/preferredCities'
import { fetchPreferredCompanies } from '../actions/preferredCompanies'
import { fetchPreferredUserSkills } from '../actions/preferredUserSkills'

export class ConverterNewJobSpecificationDetails {
    static async fromRaw(
        rawSpecification: IRawUserNewJobSpecificationDetails
    ): Promise<IUserNewJobSpecificationDetails> {
        const {
            uuid,
            preferredContractTypeIds,
            preferredRemote,
            preferredSalary,
            customCriteriaDescription,
        } = rawSpecification
        const [
            blacklistedCompanies,
            preferredCities,
            preferredCompanies,
            preferredSkills,
        ] = await Promise.all([
            fetchBlacklistedCompanies(uuid),
            fetchPreferredCities(uuid),
            fetchPreferredCompanies(uuid),
            fetchPreferredUserSkills(uuid),
        ])

        return {
            ...ConverterNewJobSpecification.fromRaw(rawSpecification),
            customCriteriaDescription,
            preferredContractTypeIds,
            preferredRemote,
            preferredSalary: { value: preferredSalary, currency: 'PLN' },
            blacklistedCompanies,
            preferredCities,
            preferredCompanies,
            preferredSkills,
        }
    }

    static toPartialRaw(
        specificationConfig: Partial<IUserNewJobSpecificationConfig>
    ): Partial<IRawUserNewJobSpecificationConfig> {
        const {
            name,
            customCriteriaDescription,
            preferredContractTypeIds,
            preferredRemote,
            preferredSalary,
        } = specificationConfig

        return {
            ...(name != null && { name }),
            ...(customCriteriaDescription != null && { customCriteriaDescription }),
            ...(preferredContractTypeIds && { preferredContractTypeIds }),
            ...(preferredRemote !== undefined && { preferredRemote }),
            ...(preferredSalary != null && { preferredSalary: preferredSalary.value }),
        }
    }
}

import { PERMISSION } from '@/enums'

export function getPermissionsFromTokenPayload(ids: number[]): PERMISSION[] {
    const result = new Set<PERMISSION>()

    ids.forEach((id) => {
        const permissions = PERMISSIONS_BY_ID.get(id)

        if (!permissions) return

        permissions.forEach((permission) => result.add(permission))
    })

    return [...result]
}

const PERMISSIONS_BY_ID: Map<number, PERMISSION[]> = new Map([
    [1, [PERMISSION.CAN_READ_JOBS]],
    [2, [PERMISSION.CAN_CREATE_JOBS]],
    [3, [PERMISSION.CAN_UPDATE_JOBS]],
    [4, [PERMISSION.CAN_DELETE_JOBS]],
])

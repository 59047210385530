import { API, createApiCache, createApiCacheStaticResourceGetter } from '@/api'
import { IUuid, IJobReferralRejectionReason } from '@/types'
import { fetchReferralRejectionReasons } from './fetchReferralRejectionReasons'

const cache = createApiCache<IUuid, IJobReferralRejectionReason>()
const { getCachedItem } = createApiCacheStaticResourceGetter<
    IUuid,
    IJobReferralRejectionReason
>(cache, 'id', fetchReferralRejectionReasons)

export async function fetchReferralRejectionReason(
    reasonId: IUuid
): Promise<IJobReferralRejectionReason> {
    const reason = await getCachedItem(reasonId)

    if (!reason) {
        throw new Error(`Job referral rejection reason with id: "${reasonId}" does not exist`)
    }

    return reason
}

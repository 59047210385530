import { ACTION_DASHBOARD_REFERRED_PEOPLE, MUTATION_DASHBOARD_REFERRED_PEOPLE } from '@/enums'
import { REPOSITORY_USER_REFERRED_PEOPLE } from '@/repositiories'
import { IUserReferredPerson, IStateDashboardReferredPeople, IStateRoot } from '@/types'
import { ActionTree, MutationTree } from 'vuex'

const state = (): IStateDashboardReferredPeople => ({
    referredPeople: undefined,
})

export const mutations: MutationTree<IStateDashboardReferredPeople> = {
    [MUTATION_DASHBOARD_REFERRED_PEOPLE.SET_REFERRED_PEOPLE](
        state,
        referredPeople: IUserReferredPerson[]
    ) {
        state.referredPeople = referredPeople
    },
}

export const actions: ActionTree<IStateDashboardReferredPeople, IStateRoot> = {
    async [ACTION_DASHBOARD_REFERRED_PEOPLE.FETCH_AND_SET_REFERRED_PEOPLE](
        { commit },
        userId: number
    ): Promise<void> {
        try {
            const referredPeople = await REPOSITORY_USER_REFERRED_PEOPLE.fetchReferredPeople(
                userId
            )

            commit(MUTATION_DASHBOARD_REFERRED_PEOPLE.SET_REFERRED_PEOPLE, referredPeople)
        } catch (error) {
            alert(error.data.message)
        }
    },
}

export const dashboardReferredPeople = {
    namespaced: true,
    state,
    mutations,
    actions,
}

import { ApiCache } from './models'
import { IApiCacheOptions } from './types'

const cacheStorage: ApiCache<unknown, unknown>[] = []

export function createApiCache<K, V>(options?: IApiCacheOptions): ApiCache<K, V> {
    const cache = new ApiCache<K, V>(options)

    cacheStorage.push(cache)

    return cache
}

export function clearApiCache(): void {
    cacheStorage.forEach((cache) => cache.clear())
}

export function createApiCacheStaticResourceGetter<K, V>(
    cache: ApiCache<K, V>,
    keyName: keyof V,
    updateHandler: () => Promise<V[]>
) {
    return (() => {
        let request: Promise<V[]> | undefined
        const updateCache = async () => {
            if (!request) request = updateHandler()

            const items = await request

            request = undefined

            items.forEach((item) => cache.set((item[keyName] as unknown) as K, item))
        }
        const getCachedItem = async (key: K) => {
            if (!cache.get(key)) await updateCache()

            return cache.get(key)
        }

        return { getCachedItem }
    })()
}

export function getFormattedKnowledgeLevel(level: number): string {
    switch (true) {
        case level === 0:
            return 'Newbie'
        case level < 25:
            return 'Newbie/Junior'
        case level === 25:
            return 'Junior'
        case level < 50:
            return 'Junior/Regular'
        case level === 50:
            return 'Regular'
        case level < 75:
            return 'Regular/Senior'
        case level === 75:
            return 'Senior'
        case level < 100:
            return 'Senior/Expert'
        default:
            return `Expert`
    }
}

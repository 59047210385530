import { API } from '@/api'
import { ICity, IUuid } from '@/types'

export async function createPreferredCity(
    specificationUuid: IUuid,
    city: ICity
): Promise<ICity> {
    const { data } = await API.post<ICity>(
        `users/me/new-job-specifications/${specificationUuid}/preferred-cities`,
        city
    )

    return data
}
